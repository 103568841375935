import ProductFormSchema, { ImageFileSchema } from 'Product/types/FormSchema';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormRegister,
} from 'react-hook-form';

interface Props {
  control: Control<ProductFormSchema>;
  register: UseFormRegister<ProductFormSchema>;
  field: FieldArrayWithId<ImageFileSchema>;
  index: number;
  showRemoveButton: boolean;
  disabled?: boolean;
  onRemove?: (index: number) => void;
  onChange?: (index: number, file?: File) => void;
  file?: File;
}

export default function ProductFormImageInput({
  control,
  register,
  field,
  index,
  showRemoveButton,
  disabled = false,
  onRemove = () => {},
  onChange = () => {},
  file,
}: Props) {
  const [preview, setPreview] = useState<string>(
    // @ts-ignore (FieldArrayWithId<ImageFileSchema>)
    file ? window.URL.createObjectURL(file) : field.source
  );
  return (
    <div className="field" key={field.id}>
      {showRemoveButton && (
        <div className="remove">
          <button
            type="button"
            onClick={() => onRemove(index)}
            disabled={disabled}
          >
            <span className="material-icons">remove_circle</span>
          </button>
        </div>
      )}
      <input type="hidden" {...register(`images.${index}.assetId`)} />
      <div className="label">Imagen</div>
      <Controller
        control={control}
        name={`images.${index}.file`}
        render={({ field: { value, onChange: handleChange, ...field } }) => {
          return (
            <input
              type="file"
              accept="image/*"
              disabled={disabled}
              // @ts-ignore (not sure why `value` is of type `never`)
              value={value?.fileName}
              onChange={(event) => {
                if (event.target.files) {
                  const file = event.target.files[0];
                  handleChange(file);
                  setPreview(window.URL.createObjectURL(file));
                  onChange(index, file);
                }
              }}
              {...field}
            />
          );
        }}
      />
      {preview && (
        <div className="thumbnail">
          <img src={preview} alt="Vista previa" />
        </div>
      )}
    </div>
  );
}
