import { useQuery } from '@apollo/client';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import { useParams } from 'react-router-dom';
import {
  supplierXorderOutputQuery,
  xorderShippingMethodsQuery,
} from 'Order/queries';
import OrderDetailsAddress from './Address';
import OrderOutput from 'Order/types/Output';
import OrderStatus from '../Status';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'Common/store/hooks';
import { selectFulfillmentAlert, setFulfillmentAlert } from 'Order/store';
import OrderDetailsPackageForm from './PackageForm';
import formatWeight from 'Order/utils/formatWeight';
import useRole from 'Auth/utils/useRole';
import CommonNotFound from 'Common/components/NotFound';

export default function OrderDetailsSupplier() {
  const dispatch = useAppDispatch();
  const fulfillmentAlert = useAppSelector(selectFulfillmentAlert);
  const { orderId } = useParams();
  const { loading, error, data } = useQuery(supplierXorderOutputQuery, {
    fetchPolicy: 'no-cache',
    variables: { orderId },
  });
  const [alertShown, setAlertShown] = useState(false);
  const {
    loading: shippingMethodsLoading,
    error: shippingMethodsError,
    data: shippingMethodsData,
  } = useQuery(xorderShippingMethodsQuery); // cached
  const { loading: roleLoading, error: roleError, data: role } = useRole();

  useEffect(() => {
    if (fulfillmentAlert) {
      setAlertShown(true);
      dispatch(setFulfillmentAlert(false));
    }
  }, [dispatch, fulfillmentAlert]);

  if (loading || shippingMethodsLoading || roleLoading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonNotFound />;
    // TODO: handle other error types
  }

  if (shippingMethodsError || roleError) {
    return <CommonErrorScreen />;
  }

  const orderOutput: OrderOutput = data?.supplierXorderOutput;
  if (!orderOutput) {
    return null;
  }
  const { order, packages } = orderOutput;
  const { code, state, orderPlacedAt } = order;
  const _package = packages[0]; // there should be only one
  const { lines } = _package;
  const totalQuantity = lines.reduce((acc, x) => acc + x.quantity, 0);
  const shippingMethods = shippingMethodsData?.xorderShippingMethods.items;

  return (
    <>
      <h1>Orden (Nº {code})</h1>
      {alertShown && (
        <div className="success">
          <p>El número de seguimiento ha sido guardado.</p>
        </div>
      )}
      <p>
        Fecha: {dayjs(orderPlacedAt).format('DD-MMM-YYYY HH:mm')}
        <br />
        Estado: <OrderStatus orderState={state} />
      </p>
      <OrderDetailsAddress order={order} />
      <p>
        Envío Express
        <br />
        Entrega en 2-5 días hábiles
      </p>
      <h2>Detalles de la orden</h2>
      <p style={{ marginBottom: 20 }}>Productos: {totalQuantity}</p>
      {lines
        .sort((a, b) =>
          a.productVariant.product.name > b.productVariant.product.name ? 1 : -1
        )
        .map((x, i) => (
          <p key={i} style={{ marginTop: 0 }}>
            {x.productVariant.product.name}{' '}
            {formatWeight(x.productVariant.customFields.weight)} x{x.quantity}
          </p>
        ))}
      {shippingMethods && role && (
        <OrderDetailsPackageForm
          orderPackage={_package}
          order={order}
          shippingMethods={shippingMethods}
          role={role}
        />
      )}
    </>
  );
}
