import { useNavigate } from 'react-router-dom';
import CommonMenuLogout from './Logout';
import logo from './logo.svg';
import CommonLoading from '../Loading';
import useIsManagerOrSuperAdmin from 'Auth/utils/useIsManager';
import CommonErrorScreen from '../ErrorScreen';

export default function CommonMenu() {
  const navigate = useNavigate();
  const { loading, error, data: isManager } = useIsManagerOrSuperAdmin(); // TODO: useRole

  function handleAdminClick() {
    navigate('/administradores');
  }
  function handleCategoryClick() {
    navigate('/categorias-de-productos');
  }
  function handleProductClick() {
    navigate('/productos');
  }
  function handleUserClick() {
    navigate('/usuarios');
  }
  function handleOrderClick() {
    navigate('/ordenes');
  }

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  return (
    <>
      <div className="logo">
        <img alt="Amazonical" src={logo} />
      </div>
      {isManager && (
        <div className="link">
          <button type="button" onClick={handleAdminClick}>
            Administradores{' '}
            <div className="float_right">
              <span className="material-icons">arrow_forward_ios</span>
            </div>
          </button>
        </div>
      )}
      {isManager && (
        <div className="link">
          <button type="button" onClick={handleCategoryClick}>
            Categorías de productos{' '}
            <div className="float_right">
              <span className="material-icons">arrow_forward_ios</span>
            </div>
          </button>
        </div>
      )}
      {isManager && (
        <div className="link">
          <button type="button" onClick={handleProductClick}>
            Productos{' '}
            <div className="float_right">
              <span className="material-icons">arrow_forward_ios</span>
            </div>
          </button>
        </div>
      )}
      {isManager && (
        <div className="link">
          <button type="button" onClick={handleUserClick}>
            Usuarios{' '}
            <div className="float_right">
              <span className="material-icons">arrow_forward_ios</span>
            </div>
          </button>
        </div>
      )}
      <div className="link">
        <button type="button" onClick={handleOrderClick}>
          Órdenes{' '}
          <div className="float_right">
            <span className="material-icons">arrow_forward_ios</span>
          </div>
        </button>
      </div>
      <CommonMenuLogout />
    </>
  );
}
