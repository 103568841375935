import { z } from 'zod';

export const customerBasicsSchema = z.object({
  firstName: z
    .string()
    .nonempty('Campo requerido')
    .refine(
      (value) => {
        return value && value.trim() !== '';
      },
      {
        message: 'Campo requerido',
      }
    )
    .transform((value) => {
      return value.trim();
    }),
  lastName: z
    .string()
    .nonempty('Campo requerido')
    .refine(
      (value) => {
        return value && value.trim() !== '';
      },
      {
        message: 'Campo requerido',
      }
    )
    .transform((value) => {
      return value.trim();
    }),
  emailAddress: z
    .string()
    .nonempty('Campo requerido')
    .email('Correo electrónico inválido'),
  subscription: z.boolean({ required_error: 'Campo requerido' }),
});

type CustomerBasics = z.infer<typeof customerBasicsSchema>;
export default CustomerBasics;
