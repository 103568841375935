import CommonLoading from 'Common/components/Loading';
import OrderList from '../List';
import OrderViewAlert from './Alert';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import { useQuery } from '@apollo/client';
import { managerOrdersQuery } from 'Order/queries';

// props
interface Props {
  customerId?: string;
  customerName?: string;
}

//
// component
//
export default function OrderViewManager({ customerId, customerName }: Props) {
  const { loading, error, data } = useQuery(managerOrdersQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      customerId,
    },
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const items = data?.managerOrders.items ?? [];

  return (
    <>
      <h1>Órdenes {customerName && `(${customerName})`}</h1>
      <OrderViewAlert />
      <OrderList items={items} />
    </>
  );
}
