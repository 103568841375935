import AddressForm from '../Form';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { xcustomerQuery } from 'Customer/queries';
import AddressBasics from 'Address/types/Basics';
import { useAppDispatch } from 'Common/store/hooks';
import { setUpdatingAlert } from 'Address/store';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';

const updateXaddressMutation = gql`
  mutation UpdateXaddress(
    $addressId: ID!
    $province: String!
    $city: String!
    $streetLine1: String!
    $postalCode: String!
    $phoneNumber: String!
    $fullName: String!
    $firstName: String!
    $lastName: String!
    $additionalInfo: String
  ) {
    updateXaddress(
      input: {
        id: $addressId
        province: $province
        city: $city
        streetLine1: $streetLine1
        postalCode: $postalCode
        phoneNumber: $phoneNumber
        fullName: $fullName
        customFields: {
          firstName: $firstName
          lastName: $lastName
          additionalInfo: $additionalInfo
        }
      }
    ) {
      __typename
    }
  }
`;

export default function AddressUpdate() {
  const dispatch = useAppDispatch();
  const { customerId, addressId } = useParams();
  const navigate = useNavigate();
  const [updateXaddress, { loading: updateLoading, error: updateError }] =
    useMutation(updateXaddressMutation, {
      refetchQueries: [xcustomerQuery],
    });
  const [globalError, setGlobalError] = useState(false);
  const {
    loading: fetchLoading,
    error: fetchError,
    data: fetchData,
  } = useQuery(xcustomerQuery, {
    fetchPolicy: 'no-cache', // can be optimized
    variables: {
      id: customerId,
    },
  });

  async function handleSubmit(data: AddressBasics) {
    setGlobalError(false);
    const { firstName, lastName } = data;
    try {
      await updateXaddress({
        variables: {
          addressId,
          fullName: `${firstName} ${lastName}`,
          ...data,
        },
      });
      dispatch(setUpdatingAlert(true));
      navigate('..');
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  if (fetchLoading) {
    return <CommonLoading />;
  }

  if (fetchError) {
    return <CommonErrorScreen />;
  }

  const defaultValues: AddressBasics | undefined =
    fetchData?.xcustomer.addresses
      .map((x: any) => ({
        ...x,
        firstName: x.customFields.firstName,
        lastName: x.customFields.lastName,
        additionalInfo: x.customFields.additionalInfo,
      }))
      .find((x: any) => x.id === addressId);

  return (
    <>
      <h1>Editar dirección de envío</h1>
      {defaultValues && (
        <AddressForm
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          disabled={updateLoading}
          isError={!!updateError || globalError}
        />
      )}
    </>
  );
}
