import { z } from 'zod';
import { categoryBasicsSchema } from './BasicsSchema';

export const categoryCreateSchema = categoryBasicsSchema.merge(
  z.object({
    thumbnail: z.any().optional(),
  })
);

type CategoryCreateSchema = z.infer<typeof categoryCreateSchema>;
export default CategoryCreateSchema;
