import { useQuery } from '@apollo/client';
import OrderDetailsView from './View';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import { useParams } from 'react-router-dom';
import { managerXorderOutputQuery, xorderPaymentsQuery } from 'Order/queries';

export default function OrderDetailsManager() {
  const { orderId } = useParams();
  const { loading, error, data } = useQuery(managerXorderOutputQuery, {
    fetchPolicy: 'no-cache',
    variables: { orderId },
  });
  const {
    loading: paymentsLoading,
    error: paymentsError,
    data: paymentsData,
  } = useQuery(xorderPaymentsQuery, {
    fetchPolicy: 'no-cache',
    variables: { orderId },
  });

  if (loading || paymentsLoading) {
    return <CommonLoading />;
  }

  if (error || paymentsError) {
    return <CommonErrorScreen />;
  }

  const orderOutput = data?.managerXorderOutput;
  const orderPayments = paymentsData?.xorderPayments;

  return (
    <>
      {orderOutput && (
        <OrderDetailsView
          orderOutput={orderOutput}
          orderPayments={orderPayments}
        />
      )}
    </>
  );
}
