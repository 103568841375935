import { z } from 'zod';
import CategoryStatus from './Status';

export const categoryBasicsSchema = z.object({
  status: z.nativeEnum(CategoryStatus),
  name: z
    .string()
    .nonempty('Campo requerido')
    .transform((value) => {
      return value.trim();
    }),
  slug: z
    .string()
    .nonempty('Campo requerido')
    .transform((value) => {
      return value.trim();
    }),
  metaTitle: z
    .string()
    .optional()
    .transform((value) => {
      return value?.trim();
    }),
  metaDescription: z
    .string()
    .optional()
    .transform((value) => {
      return value?.trim();
    }),
});

type CategoryBasicsSchema = z.infer<typeof categoryBasicsSchema>;
export default CategoryBasicsSchema;
