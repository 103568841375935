import { useQuery } from '@apollo/client';
import { Link, Typography } from '@mui/material';
import AdminListCard from './Card';
import { getXadminsQuery } from 'Admin/queries';
import CommonLoading from 'Common/components/Loading';

export default function AdminList() {
  const { data, loading, error } = useQuery(getXadminsQuery, {
    fetchPolicy: 'no-cache',
  });

  function onTryAgainClick() {
    window.location.reload();
  }

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return (
      <Typography sx={{ color: 'error.main' }}>
        Ha ocurrido un error.{' '}
        <Link component="button" color="error" onClick={onTryAgainClick}>
          Inténtalo de nuevo
        </Link>
      </Typography>
    );
  }

  const xadmins: any[] | undefined = data?.xadmins.items;

  return (
    <>
      {xadmins && xadmins.length === 0 && (
        <Typography sx={{ color: 'text.secondary' }}>
          Aún no hay administradores.
        </Typography>
      )}
      {data?.xadmins.items
        .sort((a: any, b: any) => (a.firstName > b.firstName ? 1 : -1))
        .map((x: any, i: number) => {
          const {
            id,
            firstName,
            customFields: { status, type },
          } = x;
          return (
            <AdminListCard
              key={i}
              id={id}
              status={status}
              displayName={firstName}
              type={type}
            />
          );
        })}
    </>
  );
}
