import { ZodObject, z } from 'zod';

type ProductThumbnailSchema = {
  thumbnail?: any;
};

export function makeThumbnailSchema(
  required = false
): ZodObject<ProductThumbnailSchema> {
  return z.object({
    thumbnail: z.any().refine((x) => (required ? !!x : true)),
  });
}

export default ProductThumbnailSchema;
