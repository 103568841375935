import { gql } from '@apollo/client';

export const managerOrdersQuery = gql`
  query ManagerOrders($customerId: ID) {
    managerOrders(customerId: $customerId) {
      items {
        id
        orderPlacedAt
        code
        state
      }
    }
  }
`;

export const managerXorderOutputQuery = gql`
  query ManagerXorderOutputQuery($orderId: ID!) {
    managerXorderOutput(orderId: $orderId) {
      order {
        id
        orderPlacedAt
        code
        state
        subTotal
        total
        shippingAddress {
          country
          province
          city
          streetLine1
          postalCode
          phoneNumber
          customFields {
            firstName
            lastName
            additionalInfo
          }
        }
      }
      packages {
        name
        method
        trackingCode
        lines {
          linePrice
          quantity
          productVariant {
            product {
              name
            }
            customFields {
              weight
            }
          }
        }
        supplier {
          id
          firstName
        }
      }
    }
  }
`;

export const xorderPaymentsQuery = gql`
  query XorderPaymentsQuery($orderId: ID!) {
    xorderPayments(orderId: $orderId) {
      amount
      cardBrand
      cardLast4
    }
  }
`;

export const supplierXorderOutputQuery = gql`
  query SupplierXorderOutputQuery($orderId: ID!) {
    supplierXorderOutput(orderId: $orderId) {
      order {
        id
        orderPlacedAt
        code
        state
        shippingAddress {
          country
          province
          city
          streetLine1
          postalCode
          phoneNumber
          customFields {
            firstName
            lastName
            additionalInfo
          }
        }
      }
      packages {
        method
        trackingCode
        lines {
          quantity
          productVariant {
            product {
              name
            }
            customFields {
              weight
            }
          }
        }
        supplier {
          id
          firstName
        }
      }
    }
  }
`;

export const xorderShippingMethodsQuery = gql`
  query XorderShippingMethodsQuery {
    xorderShippingMethods {
      items {
        id
        name
        code
      }
    }
  }
`;
