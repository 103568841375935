import { z } from 'zod';
import { productBasicsFormSchema } from './BasicsFormSchema';
import { productVariantSchema } from './VariantSchema';
import { makeThumbnailSchema } from './ThumbnailSchema';

const imageFileSchema = z.object({
  assetId: z.string().optional(),
  source: z.string().optional(),
  file: z.any().optional(),
});

export type ImageFileSchema = z.infer<typeof imageFileSchema>;

export const productFormSchema = productBasicsFormSchema
  .merge(
    z.object({
      variants: productVariantSchema.array().min(1),
    })
  )
  .merge(
    z.object({
      deleteExistingThumbnail: z.enum(['true', 'false']).default('false'),
    })
  )
  .merge(makeThumbnailSchema())
  .merge(
    z.object({
      images: imageFileSchema.array(),
    })
  );

type ProductFormSchema = z.infer<typeof productFormSchema>;
export default ProductFormSchema;
