import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    error: {
      main: '#f08080',
    },
  },
  typography: {
    fontFamily: 'Mulish, sans-serif',
  },
});

export default theme;
