import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';

// state definition
interface CustomerState {
  creationAlert: boolean;
  updatingAlert: boolean;
  deletionAlert: boolean;
}

// initial state
const initialState: CustomerState = {
  creationAlert: false,
  updatingAlert: false,
  deletionAlert: false,
};

// slice
export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCreationAlert: (state, action: PayloadAction<boolean>) => {
      state.creationAlert = action.payload;
    },
    setUpdatingAlert: (state, action: PayloadAction<boolean>) => {
      state.updatingAlert = action.payload;
    },
    setDeletionAlert: (state, action: PayloadAction<boolean>) => {
      state.deletionAlert = action.payload;
    },
  },
});

// actions
export const { setCreationAlert, setUpdatingAlert, setDeletionAlert } =
  customerSlice.actions;

// selectors
export const selectCreationAlert = (state: RootState) =>
  state.customer.creationAlert;
export const selectUpdatingAlert = (state: RootState) =>
  state.customer.updatingAlert;
export const selectDeletionAlert = (state: RootState) =>
  state.customer.deletionAlert;

// reducer
export default customerSlice.reducer;
