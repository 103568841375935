import { useNavigate, useParams } from 'react-router-dom';
import AddressList from '../List';
import { useQuery } from '@apollo/client';
import { xcustomerQuery } from 'Customer/queries';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CustomerBasics from 'Customer/types/Basics';
import { useAppDispatch, useAppSelector } from 'Common/store/hooks';
import {
  selectCreationAlert,
  selectDeletionAlert,
  selectUpdatingAlert,
  setCreationAlert,
  setDeletionAlert,
  setUpdatingAlert,
} from 'Address/store';
import { useEffect, useState } from 'react';

export default function AddressView() {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const creationAlert = useAppSelector(selectCreationAlert);
  const [creationAlertShown, setCreationAlertShown] = useState(false);
  const updatingAlert = useAppSelector(selectUpdatingAlert);
  const [updatingAlertShown, setUpdatingAlertShown] = useState(false);
  const deletionAlert = useAppSelector(selectDeletionAlert);
  const [deletionAlertShown, setDeletionAlertShown] = useState(false);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(xcustomerQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: customerId,
    },
  });

  useEffect(() => {
    if (creationAlert) {
      setCreationAlertShown(true);
      dispatch(setCreationAlert(false));
    }
  }, [dispatch, creationAlert]);

  useEffect(() => {
    if (updatingAlert) {
      setUpdatingAlertShown(true);
      dispatch(setUpdatingAlert(false));
    }
  }, [dispatch, updatingAlert]);

  useEffect(() => {
    if (deletionAlert) {
      setCreationAlertShown(false);
      setUpdatingAlertShown(false);
      setDeletionAlertShown(true);
      dispatch(setDeletionAlert(false));
    }
  }, [dispatch, deletionAlert]);

  function handleCreateClick() {
    navigate('agregar');
  }

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const customerBasics: CustomerBasics | undefined = data?.xcustomer;
  if (!customerBasics) {
    return null;
  }
  const { firstName, lastName } = customerBasics;

  return (
    <>
      <h1>
        Direcciones de envío ({firstName} {lastName})
      </h1>
      <div className="add">
        <button type="button" onClick={handleCreateClick}>
          Dirección de envío{' '}
          <span className="material-icons">add_circle_outline</span>
        </button>
      </div>
      {creationAlertShown && (
        <div className="success">
          <p>La dirección de envío ha sido agregada.</p>
        </div>
      )}
      {updatingAlertShown && (
        <div className="success">
          <p>La dirección de envío ha sido actualizada.</p>
        </div>
      )}
      {deletionAlertShown && (
        <div className="success">
          <p>La dirección de envío ha sido eliminada.</p>
        </div>
      )}
      <AddressList />
    </>
  );
}
