import { zodResolver } from '@hookform/resolvers/zod';
import {
  FieldArrayWithId,
  Control,
  Controller,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import ProductFormBasics from '../FormBasics/FormBasics';
import ProductCreateSchema, {
  productCreateSchema,
} from 'Product/types/CreateSchema';
import XadminStatus from 'Admin/types/xadmin-status';
import { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import CommonCancelButton from '../../../Common/components/CancelButton';

// props
interface Props {
  collections: { id: string; name: string; isPrivate: boolean }[];
  suppliers: { id: string; name: string; status: XadminStatus }[];
  disabled?: boolean;
  onSubmit?: (data: ProductCreateSchema) => void;
}

interface ImageInputProps {
  control: Control<ProductCreateSchema>;
  field: FieldArrayWithId;
  index: number;
  showRemoveButton: boolean;
  disabled?: boolean;
  onRemove?: (index: number) => void;
}
function ImageInput({
  control,
  field,
  index,
  showRemoveButton,
  disabled = false,
  onRemove = () => {},
}: ImageInputProps) {
  const [preview, setPreview] = useState<string>();
  return (
    <div className="field" key={field.id}>
      {showRemoveButton && (
        <div className="remove">
          <button
            type="button"
            onClick={() => onRemove(index)}
            disabled={disabled}
          >
            <span className="material-icons">remove_circle</span>
          </button>
        </div>
      )}
      <div className="label">Imagen</div>
      <Controller
        control={control}
        name={`images.${index}.file`}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <input
              type="file"
              accept="image/*"
              disabled={disabled}
              value={value?.fileName}
              onChange={(event) => {
                if (event.target.files) {
                  const file = event.target.files[0];
                  onChange(file);
                  setPreview(window.URL.createObjectURL(file));
                }
              }}
              {...field}
            />
          );
        }}
      />
      {preview && (
        <div className="thumbnail">
          <img src={preview} alt="Vista previa" />
        </div>
      )}
    </div>
  );
}

//
// component
//
export default function ProductCreateForm({
  collections,
  suppliers,
  disabled = false,
  onSubmit = () => {},
}: Props) {
  const [thumbnailFieldVisible, setThumbnailFieldVisible] = useState(true);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    watch,
  } = useForm<ProductCreateSchema>({
    resolver: zodResolver(productCreateSchema),
    defaultValues: {
      variants: [
        {
          size: '' as never,
          price: '' as never,
          enabled: true,
        },
      ],
      images: [
        {
          file: undefined,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variants',
  });
  const {
    fields: imageFields,
    append: imageAppend,
    remove: imageRemove,
  } = useFieldArray({
    control,
    name: 'images',
  });

  // somewhat hacky
  useEffect(() => {
    if (!thumbnailFieldVisible) {
      setThumbnailFieldVisible(true);
    }
  }, [thumbnailFieldVisible]);

  function handleVariantAppend() {
    append({
      size: '' as never,
      price: '' as never,
      enabled: true,
    });
  }
  function handleVariantRemove(index: number) {
    remove(index);
    if (fields.length === 1) {
      append({
        size: '' as never,
        price: '' as never,
        enabled: true,
      });
    }
  }

  function handleImageAppend() {
    imageAppend({
      file: undefined,
    });
  }
  function handleImageRemove(index: number) {
    imageRemove(index);
    if (imageFields.length === 1) {
      imageAppend({
        file: undefined,
      });
    }
  }

  function handleOnBlur(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const regex = /^(\d*\.?\d*)/;
    const match = e.target.value.match(regex);
    const newValue = match?.[1] ? parseFloat(match[1]) : 0;
    setValue(`variants.${index}.price`, newValue.toFixed(2) as never);
  }

  function handleThumbnailRemove() {
    resetField('thumbnail', {
      defaultValue: undefined,
    });
    setThumbnailFieldVisible(false);
  }

  const thumbnail = watch('thumbnail');
  let objectUrl: string | undefined = undefined;
  if (thumbnail) {
    objectUrl = window.URL.createObjectURL(thumbnail);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ProductFormBasics
        // @ts-ignore
        register={register}
        errors={errors}
        collections={collections}
        suppliers={suppliers}
        disabled={disabled}
        watch={watch}
        setValue={setValue}
      />
      <div className="field">
        <div className="label">Miniatura</div>
        {thumbnailFieldVisible && (
          <Controller
            control={control}
            name="thumbnail"
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <input
                  type="file"
                  accept="image/*"
                  disabled={disabled}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files && event.target.files[0]);
                  }}
                  {...field}
                />
              );
            }}
          />
        )}
        {objectUrl && (
          <div className="thumbnail">
            <div className="remove">
              <button type="button" onClick={handleThumbnailRemove}>
                <span className="material-icons">remove_circle</span>
              </button>
            </div>
            <img src={objectUrl} alt="Miniatura" />
          </div>
        )}
      </div>
      {imageFields.map((field, index) => (
        <ImageInput
          key={field.id}
          field={field}
          index={index}
          control={control}
          showRemoveButton={index > 0 || !!watch(`images.${index}.file`)}
          disabled={disabled}
          onRemove={handleImageRemove}
        />
      ))}
      <div className="add">
        <button type="button" onClick={handleImageAppend}>
          Imagen <span className="material-icons">add_circle_outline</span>
        </button>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <div className="row">
              <div className="col-lg-6 padding_no">
                <div className="field" style={{ marginBottom: 0 }}>
                  <div className="label">Tamaño (g)*</div>
                  <input
                    type="text"
                    {...register(`variants.${index}.size`, {
                      setValueAs: (x) => (!!x ? Number(x) : undefined),
                    })}
                    disabled={disabled}
                  />
                  {errors.variants && errors.variants[index]?.size && (
                    <div className="validation">
                      {errors.variants[index]?.size?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 padding_no">
                <div className="field" style={{ marginBottom: 0 }}>
                  <div className="label">Precio (€)*</div>
                  <input
                    type="text"
                    {...register(`variants.${index}.price`, {
                      setValueAs: (x) => (!!x ? Number(x) : undefined),
                      onBlur: (e) => handleOnBlur(e, index),
                    })}
                    disabled={disabled}
                  />
                  {errors.variants && errors.variants[index]?.price && (
                    <div className="validation">
                      {errors.variants[index]?.price?.message}
                    </div>
                  )}
                  {(index > 0 ||
                    !!watch(`variants.${index}.size`) ||
                    !!watch(`variants.${index}.price`)) && (
                    <div className="remove">
                      <button
                        type="button"
                        onClick={() => handleVariantRemove(index)}
                        disabled={disabled}
                      >
                        <span className="material-icons">remove_circle</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Controller
                control={control}
                name={`variants.${index}.enabled`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          color="success"
                          disabled={disabled}
                          checked={value}
                          onChange={onChange}
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="add">
        <button type="button" onClick={handleVariantAppend} disabled={disabled}>
          Tamaño <span className="material-icons">add_circle_outline</span>
        </button>
      </div>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <div className="submit">
          <button type="submit" disabled={disabled}>
            Guardar
          </button>
        </div>
        <CommonCancelButton disabled={disabled} />
      </Box>
    </form>
  );
}
