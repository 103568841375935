import './App.css';
import CommonLogin from 'Common/components/Login';
import CommonLayout from 'Common/components/Layout';
import { useQuery } from '@apollo/client';
import { meQuery } from 'Common/queries';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CommonMain from 'Common/components/Main';
import AdminMain from 'Admin/components/Main';
import CommonNotFound from 'Common/components/NotFound';
import CommonLoading from 'Common/components/Loading';
import ProductMain from 'Product/components/Main';
import CategoryMain from 'Category/components/Main';
import CustomerMain from 'Customer/components/Main';
import AddressMain from 'Address/components/Main';
import OrderMain from 'Order/components/Main';

export default function App() {
  const { pathname } = useLocation();
  const { loading, error } = useQuery(meQuery);
  if (loading) {
    return <CommonLoading />;
  }
  if (error) {
    if (pathname !== '/') {
      return <Navigate to="/" replace={true} />;
    }
    return <CommonLogin />;
    // TODO: handle error types
  }
  return (
    <CommonLayout>
      <Routes>
        <Route path="/" element={<CommonMain />} />
        <Route path="/administradores/*" element={<AdminMain />} />
        <Route path="/productos/*" element={<ProductMain />} />
        <Route path="/categorias-de-productos/*" element={<CategoryMain />} />
        <Route path="/usuarios/*" element={<CustomerMain />} />
        <Route
          path="/direcciones-de-envio/:customerId/*"
          element={<AddressMain />}
        />
        <Route path="/ordenes/*" element={<OrderMain />} />
        <Route path="*" element={<CommonNotFound />} />
      </Routes>
    </CommonLayout>
  );
}
