import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import CategoryFormBasics from '../FormBasics';
import CategoryCreateSchema, {
  categoryCreateSchema,
} from 'Category/types/CreateSchema';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CommonCancelButton from '../../../Common/components/CancelButton';

interface Props {
  disabled?: boolean;
  onSubmit?: (data: CategoryCreateSchema) => void;
}

export default function CategoryCreateForm({
  disabled = false,
  onSubmit = () => {},
}: Props) {
  const [thumbnailFieldVisible, setThumbnailFieldVisible] = useState(true);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    resetField,
    setValue,
  } = useForm<CategoryCreateSchema>({
    resolver: zodResolver(categoryCreateSchema),
  });

  // somewhat hacky
  useEffect(() => {
    if (!thumbnailFieldVisible) {
      setThumbnailFieldVisible(true);
    }
  }, [thumbnailFieldVisible]);

  function handleThumbnailRemove() {
    resetField('thumbnail', {
      defaultValue: undefined,
    });
    setThumbnailFieldVisible(false);
  }

  const thumbnail = watch('thumbnail');
  let objectUrl: string | undefined = undefined;
  if (thumbnail) {
    objectUrl = window.URL.createObjectURL(thumbnail);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <CategoryFormBasics
        // @ts-ignore
        register={register}
        errors={errors}
        disabled={disabled}
        watch={watch}
        setValue={setValue}
      />
      <div className="field">
        <div className="label">Miniatura</div>
        {thumbnailFieldVisible && (
          <Controller
            control={control}
            name="thumbnail"
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <input
                  type="file"
                  accept="image/*"
                  disabled={disabled}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files && event.target.files[0]);
                  }}
                  {...field}
                />
              );
            }}
          />
        )}
        {objectUrl && (
          <div className="thumbnail">
            <div className="remove">
              <button type="button" onClick={handleThumbnailRemove}>
                <span className="material-icons">remove_circle</span>
              </button>
            </div>
            <img src={objectUrl} alt="Miniatura" />
          </div>
        )}
      </div>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <div className="submit">
          <button type="submit" disabled={disabled}>
            Guardar
          </button>
        </div>
        <CommonCancelButton disabled={disabled} />
      </Box>
    </form>
  );
}
