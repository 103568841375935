import { gql, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import AuthRole from 'Auth/types/Role';
import { useAppDispatch } from 'Common/store/hooks';
import {
  managerXorderOutputQuery,
  supplierXorderOutputQuery,
} from 'Order/queries';
import { setFulfillmentAlert } from 'Order/store';
import OrderPackage from 'Order/types/Package';
import OrderItem from 'Order/types/Item';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import OrderFulfillmentPopup from 'Common/components/OrderFulfillmentPopup';

// mutation
const managerSendXorderPackageMutation = gql`
  mutation ManagerSendXorderPackageMutation(
    $orderId: ID!
    $supplierId: ID!
    $shippingMethodId: ID!
    $trackingCode: String!
  ) {
    managerSendXorderPackage(
      input: {
        orderId: $orderId
        supplierId: $supplierId
        shippingMethodId: $shippingMethodId
        trackingCode: $trackingCode
      }
    ) {
      __typename
    }
  }
`;
const supplierSendXorderPackageMutation = gql`
  mutation SupplierSendXorderPackageMutation(
    $orderId: ID!
    $shippingMethodId: ID!
    $trackingCode: String!
  ) {
    supplierSendXorderPackage(
      input: {
        orderId: $orderId
        shippingMethodId: $shippingMethodId
        trackingCode: $trackingCode
      }
    ) {
      __typename
    }
  }
`;

// schema
const shippingMethodSchema = z.object({
  id: z.string().nonempty(),
  name: z.string().nonempty(),
  code: z.string().nonempty(),
});
type ShippingMethod = z.infer<typeof shippingMethodSchema>;
const schema = z.object({
  shippingMethodId: z.string().nonempty('Campo requerido'),
  trackingCode: z
    .string({
      invalid_type_error: 'Campo requerido',
    })
    .nonempty('Campo requerido'),
});
type Schema = z.infer<typeof schema>;

// props
interface Props {
  orderPackage: OrderPackage;
  order: OrderItem;
  shippingMethods: ShippingMethod[];
  role: AuthRole;
}

//
// component
//
export default function OrderDetailsPackageForm({
  orderPackage,
  order,
  shippingMethods,
  role,
}: Props) {
  const dispatch = useAppDispatch();
  const { orderId } = useParams(); // quick access to the variable
  const { supplier, method, trackingCode } = orderPackage;
  const _shippingMethods = [...shippingMethods].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const pickedMethod = _shippingMethods.find((x) => x.code === method);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      shippingMethodId: pickedMethod?.id,
      trackingCode,
    },
  });
  const [
    managerSendXorderPackage,
    { loading: managerLoading, error: managerError },
  ] = useMutation(managerSendXorderPackageMutation, {
    refetchQueries: [managerXorderOutputQuery],
  });
  const [
    supplierSendXorderPackage,
    { loading: supplierLoading, error: supplierError },
  ] = useMutation(supplierSendXorderPackageMutation, {
    refetchQueries: [supplierXorderOutputQuery],
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<Schema>({} as Schema);
  const [globalError, setGlobalError] = useState(false);
  const disabled = managerLoading || supplierLoading;
  const isError = globalError || !!managerError || !!supplierError;

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    if (managerLoading || supplierLoading) {
      return;
    }
    setDialogOpen(false);
    setGlobalError(false);
  }

  function scrollTop() {
    window && window.scrollTo({ top: 0, left: 0 });
  }

  async function handleSubmitManager(data: Schema) {
    const { shippingMethodId, trackingCode } = data;
    setGlobalError(false);
    try {
      await managerSendXorderPackage({
        variables: {
          orderId,
          supplierId: supplier.id,
          shippingMethodId,
          trackingCode,
        },
      });
      dispatch(setFulfillmentAlert(true));
      scrollTop();
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  async function handleSubmitSupplier(data: Schema) {
    const { shippingMethodId, trackingCode } = data;
    setGlobalError(false);
    try {
      await supplierSendXorderPackage({
        variables: {
          orderId,
          shippingMethodId,
          trackingCode,
        },
      });
      dispatch(setFulfillmentAlert(true));
      scrollTop();
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  async function _handleSubmit(data: Schema) {
    if (managerLoading || supplierLoading) {
      return;
    }
    handleDialogOpen();
    setFormData(data);
  }

  return (
    <form className="tracking" onSubmit={handleSubmit(_handleSubmit)}>
      {isError && (
        <>
          <Typography sx={{ color: 'red' }}>
            Ha ocurrido un error. Inténtalo de nuevo
          </Typography>
          <Box mb={2} />
        </>
      )}
      {order.state !== 'Cancelled' && (
        <div className="field">
          <div className="label">Empresa de envíos*</div>
          <select
            disabled={disabled}
            size={2}
            {...register('shippingMethodId')}
          >
            {_shippingMethods.map((x, i) => (
              <option key={i} value={x.id}>
                {x.name}
              </option>
            ))}
          </select>
          {errors.shippingMethodId && (
            <div className="validation">{errors.shippingMethodId.message}</div>
          )}
        </div>
      )}
      {order.state !== 'Cancelled' && (
        <div className="field">
          <div className="label">Número de seguimiento*</div>
          <input
            disabled={disabled}
            type="text"
            {...register('trackingCode')}
          />
          {errors.trackingCode && (
            <div className="validation">{errors.trackingCode.message}</div>
          )}
        </div>
      )}
      {order.state !== 'Cancelled' && (
        <div className="submit">
          <button disabled={disabled} type="submit">
            Guardar
          </button>
        </div>
      )}

      {dialogOpen && (
        <OrderFulfillmentPopup
          trackingCode={watch('trackingCode')}
          shippingMethod={
            _shippingMethods.find((x) => x.id === watch('shippingMethodId'))
              ?.name
          }
          isError={isError}
          onClose={handleDialogClose}
          onSubmit={async () => {
            if (role === AuthRole.MANAGER) {
              await handleSubmitManager(formData);
            } else if (role === AuthRole.SUPPLIER) {
              await handleSubmitSupplier(formData);
            }
            handleDialogClose();
          }}
        />
      )}
    </form>
  );
}
