export default function getDuplicateSupplierNames(suppliers?: any[]): string[] {
  if (!suppliers) {
    return [];
  }
  const uniqueNames: string[] = [];
  const duplicateNames: string[] = [];
  suppliers.forEach((x) => {
    if (!uniqueNames.includes(x.firstName)) {
      uniqueNames.push(x.firstName);
    } else {
      duplicateNames.push(x.firstName);
    }
  });
  return duplicateNames;
}
