import { gql } from '@apollo/client';

export const xcustomersQuery = gql`
  query Xcustomers {
    xcustomers {
      items {
        id
        firstName
        lastName
        emailAddress
      }
    }
  }
`;

export const xcustomerQuery = gql`
  query Xcustomer($id: ID!) {
    xcustomer(id: $id) {
      firstName
      lastName
      emailAddress
      addresses {
        id
        province
        city
        streetLine1
        postalCode
        phoneNumber
        customFields {
          firstName
          lastName
          additionalInfo
        }
      }
    }
  }
`;

export const xcustomerOutputQuery = gql`
  query XcustomerOutput($id: ID!) {
    xcustomerOutput(id: $id) {
      customer {
        firstName
        lastName
        emailAddress
      }
      subscription
    }
  }
`;
