import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import adminReducer from 'Admin/store';
import productReducer from 'Product/store';
import categoryReducer from 'Category/store';
import customerReducer from 'Customer/store';
import addressReducer from 'Address/store';
import orderReducer from 'Order/store';

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    product: productReducer,
    category: categoryReducer,
    customer: customerReducer,
    address: addressReducer,
    order: orderReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
