import { z } from 'zod';
import { categoryBasicsSchema } from './BasicsSchema';

export const categoryFormSchema = categoryBasicsSchema
  .merge(
    z.object({
      thumbnail: z.any().optional(),
    })
  )
  .merge(
    z.object({
      deleteExistingThumbnail: z.enum(['true', 'false']).default('false'),
    })
  );

type CategoryFormSchema = z.infer<typeof categoryFormSchema>;
export default CategoryFormSchema;
