import AddressForm from '../Form';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { xcustomerQuery } from 'Customer/queries';
import AddressBasics from 'Address/types/Basics';
import { useAppDispatch } from 'Common/store/hooks';
import { setCreationAlert } from 'Address/store';

const createXaddressMutation = gql`
  mutation CreateXaddress(
    $customerId: ID!
    $province: String!
    $city: String!
    $streetLine1: String!
    $postalCode: String!
    $phoneNumber: String!
    $fullName: String!
    $firstName: String!
    $lastName: String!
    $additionalInfo: String
  ) {
    createXaddress(
      customerId: $customerId
      input: {
        countryCode: "ES"
        province: $province
        city: $city
        streetLine1: $streetLine1
        postalCode: $postalCode
        phoneNumber: $phoneNumber
        fullName: $fullName
        customFields: {
          firstName: $firstName
          lastName: $lastName
          additionalInfo: $additionalInfo
        }
      }
    ) {
      __typename
    }
  }
`;

export default function AddressCreate() {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [createXaddress, { loading, error }] = useMutation(
    createXaddressMutation,
    {
      refetchQueries: [xcustomerQuery],
    }
  );
  const [globalError, setGlobalError] = useState(false);

  async function handleSubmit(data: AddressBasics) {
    setGlobalError(false);
    const { firstName, lastName } = data;
    try {
      await createXaddress({
        variables: {
          customerId,
          fullName: `${firstName} ${lastName}`,
          ...data,
        },
      });
      dispatch(setCreationAlert(true));
      navigate('..');
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <h1>Agregar dirección de envío</h1>
      <AddressForm
        onSubmit={handleSubmit}
        disabled={loading}
        isError={!!error || globalError}
      />
    </>
  );
}
