import { useQuery } from '@apollo/client';
import { rolesMeQuery } from 'Auth/queries';
import { useEffect, useState } from 'react';

const managerRoleCode = 'manager';
const superAdminRoleCode = '__super_admin_role__';

/**
 * @deprecated
 * @use `useRole`
 *
 * Whether the user is a Manager or a SuperAdmin.
 */
export default function useIsManagerOrSuperAdmin() {
  const { loading, error, data } = useQuery(rolesMeQuery, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const roles: any[] | undefined = data?.rolesMe; // graphql schema
    if (!roles) {
      setIsManager(false);
    } else {
      const foundRoles = roles.filter(
        (x) => x.code === managerRoleCode || x.code === superAdminRoleCode
      );
      setIsManager(foundRoles.length > 0);
    }
  }, [data]);

  const [isManager, setIsManager] = useState<boolean>();
  return { loading, error, data: isManager };
}
