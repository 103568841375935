export default function getDuplicateCategoryNames(
  categories?: any[]
): string[] {
  if (!categories) {
    return [];
  }
  const uniqueNames: string[] = [];
  const duplicateNames: string[] = [];
  categories.forEach((x) => {
    if (!uniqueNames.includes(x.name)) {
      uniqueNames.push(x.name);
    } else {
      duplicateNames.push(x.name);
    }
  });
  return duplicateNames;
}
