import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { xcollectionsQuery, xcollectionQuery } from 'Category/queries';
import { useAppDispatch } from 'Common/store/hooks';
import { setUpdatingAlert } from 'Category/store';
import CategoryStatus from 'Category/types/Status';
import CategoryUpdateForm from './Form';
import CategoryFormSchema from 'Category/types/FormSchema';

// mutation
const updateXcategoryMutation = gql`
  mutation UpdateXcategoryMutation(
    $id: ID!
    $status: XcategoryStatus
    $name: String
    $slug: String
    $metaTitle: String
    $metaDescription: String
    $deleteExistingThumbnail: Boolean
    $thumbnail: Upload
  ) {
    updateXcategory(
      input: {
        id: $id
        status: $status
        name: $name
        slug: $slug
        metaTitle: $metaTitle
        metaDescription: $metaDescription
        deleteExistingThumbnail: $deleteExistingThumbnail
        thumbnail: $thumbnail
      }
    ) {
      __typename
    }
  }
`;

//
// component
//
export default function CategoryUpdate() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [globalError, setGlobalError] = useState(false);
  const {
    loading: fetchLoading,
    error: fetchError,
    data: category,
  } = useQuery(xcollectionQuery, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const [updateXcategory, { loading: updateLoading, error: updateError }] =
    useMutation(updateXcategoryMutation, {
      refetchQueries: [xcollectionsQuery],
    });

  async function handleSubmit(data: CategoryFormSchema) {
    const { deleteExistingThumbnail } = data;
    setGlobalError(false);
    try {
      await updateXcategory({
        variables: {
          ...data,
          id,
          deleteExistingThumbnail: deleteExistingThumbnail === 'true',
        },
      });
      dispatch(setUpdatingAlert(true));
      navigate('..');
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  if (fetchLoading) {
    return <CommonLoading />;
  }

  if (fetchError) {
    return <CommonErrorScreen />;
  }

  const defaultValues = {
    ...category?.xcollection,
    status: category?.xcollection.isPrivate
      ? CategoryStatus.INACTIVE
      : CategoryStatus.ACTIVE,
    metaTitle: category?.xcollection.customFields.metaTitle,
    metaDescription: category?.xcollection.customFields.metaDescription,
    deleteExistingThumbnail: 'false',
  };

  return (
    <>
      <h1>Editar categoría de productos</h1>
      {(updateError || globalError) && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      {defaultValues && id && (
        <>
          <CategoryUpdateForm
            defaultValues={defaultValues}
            disabled={updateLoading}
            onSubmit={handleSubmit}
            defaultThumbnailUrl={category?.xcollection.featuredAsset?.source}
          />
        </>
      )}
    </>
  );
}
