import OrderItem from 'Order/types/Item';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import OrderStatus from '../Status';
import { useState } from 'react';
import useRole from 'Auth/utils/useRole';
import AuthRole from 'Auth/types/Role';
import { gql, useMutation } from '@apollo/client';
import { useAppDispatch } from 'Common/store/hooks';
import { setCancellationAlert } from 'Order/store';
import { Box } from '@mui/material';
import { managerOrdersQuery } from 'Order/queries';

// mutation
const cancelXorderMutation = gql`
  mutation CancelXorderMutation($orderId: ID!) {
    cancelXorder(orderId: $orderId) {
      __typename
    }
  }
`;

// props
interface Props {
  item: OrderItem;
}

//
// component
//
export default function OrderListCard({ item }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, code, orderPlacedAt, state } = item;
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: role } = useRole();
  const [cancelXorder, { loading, error }] = useMutation(cancelXorderMutation, {
    refetchQueries: [managerOrdersQuery],
  });
  const [globalError, setGlobalError] = useState(false);

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    if (loading) {
      return;
    }
    setDialogOpen(false);
    setGlobalError(false);
  }

  function handleViewClick() {
    navigate(`/ordenes/editar/${id}`); // absolute path
  }

  async function handleOrderCancel() {
    if (loading) {
      return;
    }
    setGlobalError(false);
    try {
      await cancelXorder({ variables: { orderId: id } });
      dispatch(setCancellationAlert(true));
      setDialogOpen(false);
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <div className="row item">
        <div className="col-lg-10 padding_no">
          <div className="content">
            <OrderStatus orderState={state} />
            <span className="column">
              {dayjs(orderPlacedAt).format('DD-MMM-YYYY HH:mm')}
            </span>
            Nº {code}
          </div>
        </div>
        <div className="col-lg-2 padding_no">
          <div className="buttons">
            <button type="button" onClick={handleViewClick}>
              <span className="material-icons">visibility</span>
            </button>
            {role === AuthRole.MANAGER && state !== 'Cancelled' && (
              <button type="button" onClick={handleDialogOpen}>
                <span className="material-icons">cancel</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {dialogOpen && (
        <div className="grayout">
          <div className="popup">
            <div className="close">
              <button type="button" onClick={handleDialogClose}>
                <span className="material-icons">close</span>
              </button>
            </div>
            <h1>¿Quieres cancelar esta orden?</h1>
            {(error || globalError) && (
              <Box className="success" sx={{ bgcolor: 'error.main' }}>
                <p>Ha ocurrido un error. Inténtalo de nuevo</p>
              </Box>
            )}
            <form>
              <div className="buttons">
                <button type="button" onClick={handleOrderCancel}>
                  Sí, cancelar orden
                </button>
                <button
                  className="cancel"
                  type="button"
                  onClick={handleDialogClose}
                >
                  No
                </button>
                {/* update in Stripe */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
