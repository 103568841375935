import { Box } from '@mui/material';
import { useAppDispatch } from 'Common/store/hooks';
import useSuppliersAndCollections from 'Product/utils/useSuppliersAndCollections';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import { gql, useMutation, useQuery } from '@apollo/client';
import { setUpdatingAlert } from 'Product/store';
import { xproductQuery } from 'Product/queries';
import ProductForm from '../Form';
import ProductFormSchema from 'Product/types/FormSchema';
import XadminStatus from 'Admin/types/xadmin-status';
import ProductStatus from 'Product/types/Status';
import getDuplicateSupplierNames from 'Product/utils/getDuplicateSupplierNames';
import getDuplicateCategoryNames from 'Product/utils/getDuplicateCategoryNames';

// mutation
const updateXproductMutation = gql`
  mutation UpdateXproductMutation(
    $id: ID!
    $status: XproductStatus
    $name: String
    $slug: String
    $body: String
    $metaTitle: String
    $metaDescription: String
    $shortDescription: String
    $nutritionalInfo: String
    $supplierId: ID
    $collectionIds: [ID!]
    $variants: [XproductVariantInput!]
    $thumbnail: Upload
    $deleteExistingThumbnail: Boolean
    $images: [XproductImageInput!]
  ) {
    updateXproduct(
      input: {
        id: $id
        status: $status
        name: $name
        slug: $slug
        body: $body
        metaTitle: $metaTitle
        metaDescription: $metaDescription
        shortDescription: $shortDescription
        nutritionalInfo: $nutritionalInfo
        supplierId: $supplierId
        collectionIds: $collectionIds
        variants: $variants
        thumbnail: $thumbnail
        deleteExistingThumbnail: $deleteExistingThumbnail
        images: $images
      }
    ) {
      __typename
    }
  }
`;

//
// component
//
export default function ProductUpdate() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateXproduct, { loading: updateLoading, error: updateError }] =
    useMutation(updateXproductMutation);
  const [globalError, setGlobalError] = useState(false);
  const {
    loading: fetchLoading,
    isError: isFetchError,
    data,
  } = useSuppliersAndCollections();
  const {
    loading: xproductLoading,
    error: xproductError,
    data: productData,
  } = useQuery(xproductQuery, {
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  async function handleSubmit(data: ProductFormSchema) {
    setGlobalError(false);
    try {
      await updateXproduct({
        variables: {
          ...data,
          id,
          variants: data.variants.map((x) => ({
            size: x.size,
            price: Math.round(x.price * 100),
            enabled: x.enabled,
          })),
          deleteExistingThumbnail: data.deleteExistingThumbnail === 'true',
          images: data.images.map((x) => ({
            assetId: x.assetId,
            file: x.file,
          })),
        },
      });
      dispatch(setUpdatingAlert(true));
      navigate('..');
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  if (fetchLoading || xproductLoading) {
    return <CommonLoading />;
  }

  if (isFetchError || xproductError) {
    return <CommonErrorScreen />;
  }

  if (!productData?.xproduct) {
    return <div>El producto no encontrado.</div>;
  }

  const duplicateSupplierNames = getDuplicateSupplierNames(
    data?.suppliers.suppliers
  );
  const suppliers =
    data?.suppliers.suppliers.map((x: any) => ({
      ...x,
      name: x.firstName,
      emailAddress: duplicateSupplierNames.includes(x.firstName)
        ? x.emailAddress
        : undefined,
      status: x.customFields.status,
    })) ?? [];
  const duplicateCategoryNames = getDuplicateCategoryNames(
    data?.collections.xcollections.items
  );
  const collections =
    data?.collections.xcollections.items.map((x: any) => ({
      ...x,
      slug: duplicateCategoryNames.includes(x.name) ? x.slug : undefined,
    })) ?? [];

  const defaultValues = productData && {
    ...productData.xproduct,
    status: productData.xproduct.customFields.status,
    metaDescription: productData.xproduct.customFields.metaDescription,
    metaTitle: productData.xproduct.customFields.metaTitle,
    shortDescription: productData.xproduct.customFields.shortDescription ?? '',
    body: productData.xproduct.customFields.body,
    nutritionalInfo: productData.xproduct.customFields.nutritionalInfo ?? '',
    supplierId: productData.xproduct.customFields.supplier?.id,
    collectionIds: productData.xproduct.collections.map((x: any) => x.id),
    variants: productData.xproduct.variants
      .map((x: any) => ({
        ...x,
        size: x.customFields.weight,
        price: (x.price / 100).toFixed(2),
      }))
      .sort((a: any, b: any) => (a.size > b.size ? 1 : -1)),
    deleteExistingThumbnail: 'false',
    images:
      productData?.xproduct.assets?.map((x: any) => ({
        assetId: x.id,
        source: x.source,
      })) ?? [],
  };

  const status = productData.xproduct.customFields.status;
  const supplierStatus =
    productData.xproduct.customFields.supplier?.customFields.status;
  const hasActiveCollections = productData.xproduct.collections.reduce(
    (acc: boolean, x: any) => acc || !x.isPrivate,
    false
  );

  return (
    <>
      <h1>Editar producto</h1>
      {(updateError || globalError) && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      {status === ProductStatus.ACTIVE &&
        supplierStatus !== XadminStatus.ACTIVE && (
          <Box
            className="success"
            sx={{ bgcolor: 'lightgoldenrodyellow' }}
            mb="1rem"
          >
            <p>
              Este producto no está asignado a un <b>proveedor</b> activo. El
              producto será visible para los usuarios cómo si estuviera del
              estado "Agotado".
            </p>
          </Box>
        )}
      {status === ProductStatus.ACTIVE && !hasActiveCollections && (
        <Box
          className="success"
          sx={{ bgcolor: 'lightgoldenrodyellow' }}
          mb="1rem"
        >
          <p>
            Este producto no está asignado a ninguna <b>categoría</b> activa. El
            producto no será visible para los usuarios.
          </p>
        </Box>
      )}
      <ProductForm
        collections={collections}
        suppliers={suppliers}
        disabled={updateLoading}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        defaultThumbnailUrl={
          productData?.xproduct.customFields.thumbnail?.source
        }
      />
    </>
  );
}
