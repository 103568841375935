import AuthRole from 'Auth/types/Role';
import useRole from 'Auth/utils/useRole';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import OrderDetailsManager from './Manager';
import OrderDetailsSupplier from './Supplier';

export default function OrderDetails() {
  const { loading, error, data: role } = useRole();

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  if (role === AuthRole.MANAGER) {
    return <OrderDetailsManager />;
  }

  if (role === AuthRole.SUPPLIER) {
    return <OrderDetailsSupplier />;
  }

  return null;
}
