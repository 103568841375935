import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'Common/store/hooks';
import { setCreationAlert } from 'Product/store';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import useSuppliersAndCollections from 'Product/utils/useSuppliersAndCollections';
import ProductCreateForm from './Form';
import ProductCreateSchema from 'Product/types/CreateSchema';
import getDuplicateSupplierNames from 'Product/utils/getDuplicateSupplierNames';
import getDuplicateCategoryNames from 'Product/utils/getDuplicateCategoryNames';

// mutation
const createXproductMutation = gql`
  mutation CreateXproduct(
    $status: XproductStatus!
    $name: String!
    $slug: String!
    $body: String
    $variants: [XproductVariantInput!]!
    $supplierId: ID
    $shortDescription: String
    $metaTitle: String
    $metaDescription: String
    $nutritionalInfo: String
    $collectionIds: [ID!]!
    $thumbnail: Upload
    $images: [Upload!]
  ) {
    createXproduct(
      input: {
        status: $status
        name: $name
        slug: $slug
        body: $body
        variants: $variants
        supplierId: $supplierId
        shortDescription: $shortDescription
        metaTitle: $metaTitle
        metaDescription: $metaDescription
        nutritionalInfo: $nutritionalInfo
        collectionIds: $collectionIds
        thumbnail: $thumbnail
        images: $images
      }
    ) {
      __typename
    }
  }
`;

export default function ProductCreate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createXproduct, { loading, error }] = useMutation(
    createXproductMutation
  );
  const [globalError, setGlobalError] = useState(false);
  const {
    loading: fetchLoading,
    isError: isFetchError,
    data,
  } = useSuppliersAndCollections();

  async function handleSubmit(data: ProductCreateSchema) {
    setGlobalError(false);
    const images = data.images?.map((x) => x.file).filter((y) => !!y);
    try {
      await createXproduct({
        variables: {
          ...data,
          variants: data.variants.map((i) => ({
            ...i,
            price: Math.round(i.price * 100),
          })),
          images,
        },
      });
      dispatch(setCreationAlert(true));
      navigate('..');
    } catch (err) {
      console.error(err);
      setGlobalError(true);
    }
  }

  if (fetchLoading) {
    return <CommonLoading />;
  }

  if (isFetchError) {
    return <CommonErrorScreen />;
  }

  const duplicateSupplierNames = getDuplicateSupplierNames(
    data?.suppliers.suppliers
  );
  const suppliers =
    data?.suppliers.suppliers.map((x: any) => ({
      ...x,
      name: x.firstName,
      emailAddress: duplicateSupplierNames.includes(x.firstName)
        ? x.emailAddress
        : undefined,
      status: x.customFields.status,
    })) ?? [];
  const duplicateCategoryNames = getDuplicateCategoryNames(
    data?.collections.xcollections.items
  );
  const collections =
    data?.collections.xcollections.items.map((x: any) => ({
      ...x,
      slug: duplicateCategoryNames.includes(x.name) ? x.slug : undefined,
    })) ?? [];
  return (
    <>
      <h1>Agregar producto</h1>
      {(error || globalError) && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      <ProductCreateForm
        collections={collections}
        suppliers={suppliers}
        disabled={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
}
