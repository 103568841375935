import { Route, Routes } from 'react-router-dom';
import AdminView from '../View';
import AdminCreate from '../Create';
import AdminUpdate from '../Update';
import CommonNotFound from 'Common/components/NotFound';
import useIsManagerOrSuperAdmin from 'Auth/utils/useIsManager';
import { Link, Typography } from '@mui/material';
import CommonLoading from 'Common/components/Loading';

export default function AdminMain() {
  const { loading, error, data: isManager } = useIsManagerOrSuperAdmin();

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return (
      <Typography sx={{ color: 'error.main' }}>
        Ha ocurrido un error.{' '}
        <Link
          component="button"
          color="error"
          onClick={() => window.location.reload()}
        >
          Inténtalo de nuevo
        </Link>
      </Typography>
    );
  }

  return (
    <Routes>
      {isManager && (
        <>
          <Route path="/" element={<AdminView />} />
          <Route path="/agregar" element={<AdminCreate />} />
          <Route path="/editar/:id" element={<AdminUpdate />} />
        </>
      )}
      <Route path="*" element={<CommonNotFound />} />
    </Routes>
  );
}
