import { useQuery } from '@apollo/client';
import AddressItem from 'Address/types/Item';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import { xcustomerQuery } from 'Customer/queries';
import { useParams } from 'react-router-dom';
import AddressListCard from './Card';
import { Typography } from '@mui/material';

export default function AddressList() {
  const { customerId } = useParams();
  const { loading, error, data } = useQuery(xcustomerQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: customerId,
    },
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const addresses: AddressItem[] | undefined = data?.xcustomer.addresses.map(
    (x: any) => ({
      ...x,
      firstName: x.customFields.firstName,
      lastName: x.customFields.lastName,
      additionalInfo: x.customFields.additionalInfo,
    })
  );

  return (
    <>
      {addresses && addresses.length === 0 && (
        <Typography sx={{ color: 'text.secondary' }}>
          Aún no hay direcciones de envío.
        </Typography>
      )}
      {addresses &&
        addresses
          .sort((a, b) =>
            `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
              ? 1
              : -1
          )
          .map((x, i) => <AddressListCard key={i} item={x} />)}
    </>
  );
}
