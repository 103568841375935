import { z } from 'zod';
import { productBasicsFormSchema } from './BasicsFormSchema';
import { productVariantSchema } from './VariantSchema';
import { makeThumbnailSchema } from './ThumbnailSchema';
import { makeFileSchema } from './FileSchema';

export const productCreateSchema = productBasicsFormSchema
  .merge(
    z.object({
      variants: productVariantSchema.array().min(1),
    })
  )
  .merge(makeThumbnailSchema())
  .merge(
    z.object({
      images: makeFileSchema().array(),
    })
  );

type ProductCreateSchema = z.infer<typeof productCreateSchema>;
export default ProductCreateSchema;
