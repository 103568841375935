import { useQuery } from '@apollo/client';
import CustomerBasics from 'Customer/types/Basics';
import { useParams } from 'react-router-dom';
import CustomerUpdateForm from './Form';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import { xcustomerOutputQuery } from 'Customer/queries';

//
// component
//
export default function CustomerUpdate() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(xcustomerOutputQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const defaultValues: CustomerBasics = {
    ...data?.xcustomerOutput.customer,
    subscription: data?.xcustomerOutput.subscription,
  };
  return defaultValues && id ? (
    <CustomerUpdateForm id={id} defaultValues={defaultValues} />
  ) : null;
}
