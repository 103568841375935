import { useNavigate } from 'react-router-dom';
import CustomerList from '../List';
import { useAppDispatch, useAppSelector } from 'Common/store/hooks';
import {
  selectCreationAlert,
  selectDeletionAlert,
  selectUpdatingAlert,
  setCreationAlert,
  setDeletionAlert,
  setUpdatingAlert,
} from 'Customer/store';
import { useEffect, useState } from 'react';

export default function CustomerView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const creationAlert = useAppSelector(selectCreationAlert);
  const [creationAlertShown, setCreationAlertShown] = useState(false);
  const updatingAlert = useAppSelector(selectUpdatingAlert);
  const [updatingAlertShown, setUpdatingAlertShown] = useState(false);
  const deletionAlert = useAppSelector(selectDeletionAlert);
  const [deletionAlertShown, setDeletionAlertShown] = useState(false);

  useEffect(() => {
    if (creationAlert) {
      setCreationAlertShown(true);
      dispatch(setCreationAlert(false));
    }
  }, [dispatch, creationAlert]);

  useEffect(() => {
    if (updatingAlert) {
      setUpdatingAlertShown(true);
      dispatch(setUpdatingAlert(false));
    }
  }, [dispatch, updatingAlert]);

  useEffect(() => {
    if (deletionAlert) {
      setCreationAlertShown(false);
      setUpdatingAlertShown(false);
      setDeletionAlertShown(true);
      dispatch(setDeletionAlert(false));
    }
  }, [dispatch, deletionAlert]);

  function handleCreateClick() {
    navigate('agregar');
  }

  return (
    <>
      <h1>Usuarios</h1>
      <div className="add">
        <button type="button" onClick={handleCreateClick}>
          Usuario <span className="material-icons">add_circle_outline</span>
        </button>
      </div>
      {creationAlertShown && (
        <div className="success">
          <p>El usuario ha sido agregado.</p>
        </div>
      )}
      {updatingAlertShown && (
        <div className="success">
          <p>El usuario ha sido actualizado.</p>
        </div>
      )}
      {deletionAlertShown && (
        <div className="success">
          <p>El usuario ha sido eliminado.</p>
        </div>
      )}
      <CustomerList />
    </>
  );
}
