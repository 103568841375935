import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';

// state definition
interface AddressState {
  creationAlert: boolean;
  updatingAlert: boolean;
  deletionAlert: boolean;
}

// initial state
const initialState: AddressState = {
  creationAlert: false,
  updatingAlert: false,
  deletionAlert: false,
};

// slice
export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setCreationAlert: (state, action: PayloadAction<boolean>) => {
      state.creationAlert = action.payload;
    },
    setUpdatingAlert: (state, action: PayloadAction<boolean>) => {
      state.updatingAlert = action.payload;
    },
    setDeletionAlert: (state, action: PayloadAction<boolean>) => {
      state.deletionAlert = action.payload;
    },
  },
});

// actions
export const { setCreationAlert, setUpdatingAlert, setDeletionAlert } =
  addressSlice.actions;

// selectors
export const selectCreationAlert = (state: RootState) =>
  state.address.creationAlert;
export const selectUpdatingAlert = (state: RootState) =>
  state.address.updatingAlert;
export const selectDeletionAlert = (state: RootState) =>
  state.address.deletionAlert;

// reducer
export default addressSlice.reducer;
