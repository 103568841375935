import ProductBasicsFormSchema from 'Product/types/BasicsFormSchema';
import ProductStatus from 'Product/types/Status';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import classes from './ProductFormBasics.module.css';
import XadminStatus from 'Admin/types/xadmin-status';
import { Editor } from '@tinymce/tinymce-react';
import ProductCreateSchema from 'Product/types/CreateSchema';
import { useEffect } from 'react';

type WatchFunction = {
  (): ProductBasicsFormSchema;
  <K extends keyof ProductBasicsFormSchema>(
    fieldName: K
  ): ProductBasicsFormSchema[K];
  <K extends keyof ProductBasicsFormSchema>(fieldNames: K[]): Pick<
    ProductBasicsFormSchema,
    K
  >;
};

interface Props {
  register: UseFormRegister<ProductBasicsFormSchema>;
  errors: FieldErrors<ProductBasicsFormSchema>;
  collections: {
    id: string;
    name: string;
    isPrivate: boolean;
    slug?: string;
  }[];
  suppliers: {
    id: string;
    name: string;
    status: XadminStatus;
    emailAddress?: string;
  }[];
  disabled?: boolean;
  watch: WatchFunction;
  setValue: UseFormSetValue<ProductCreateSchema>;
  defaultValues?: ProductCreateSchema; // TODO: reconsider this type
}

// Tinymce settings
const tinymceToolbar =
  'undo redo | blocks | bold italic forecolor link | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |removeformat | help';
const tinymceHeight = 415;
const tinymceApiKey = process.env.REACT_APP_TINYMCE_API_KEY || '';

export default function ProductFormBasics({
  register,
  errors,
  collections,
  suppliers,
  disabled = false,
  watch,
  setValue,
  defaultValues,
}: Props) {
  const watchMetaDescription = watch('metaDescription');

  useEffect(() => {
    const metaDescription = watchMetaDescription;
    setValue(
      'metaDescription',
      metaDescription?.replaceAll(/\n/g, '') as never
    );
  }, [setValue, watchMetaDescription]);

  return (
    <>
      <div className="field">
        <div className="label">Estado*</div>
        <select size={3} {...register('status')} disabled={disabled}>
          <option value={ProductStatus.ACTIVE}>Activo</option>
          <option value={ProductStatus.UNAVAILABLE}>Agotado</option>
          <option value={ProductStatus.INACTIVE}>Inactivo</option>
        </select>
        {errors.status && <div className="validation">Campo requerido</div>}
      </div>
      <div className="field">
        <div className="label">Título*</div>
        <div className="count">{(watch('name') ?? '').length}</div>
        <input
          className={classes.input}
          type="text"
          {...register('name')}
          disabled={disabled}
        />
        {errors.name && <div className="validation">{errors.name.message}</div>}
      </div>
      <div className="field">
        <div className="label">Slug*</div>
        <div className="count">{(watch('slug') ?? '').length}</div>
        <input
          className={classes.input}
          type="text"
          {...register('slug')}
          disabled={disabled}
        />
        {errors.slug && <div className="validation">{errors.slug.message}</div>}
      </div>
      <div className="field">
        <div className="label">Meta Title</div>
        <div className="count">{(watch('metaTitle') ?? '').length}</div>
        <input
          className={classes.input}
          type="text"
          {...register('metaTitle')}
        />
      </div>
      <div className="field">
        <div className="label">Meta Description</div>
        <div className="count">{(watch('metaDescription') ?? '').length}</div>
        <textarea
          className={classes.input}
          rows={2}
          {...register('metaDescription')}
          disabled={disabled}
        />
      </div>
      <div className="field">
        <div className="label">Proveedor</div>
        <select size={3} {...register('supplierId')} disabled={disabled}>
          <option value="NONE" style={{ fontStyle: 'italic' }}>
            - Ninguno -
          </option>
          {suppliers
            .sort((a, b) =>
              `${a.name}${a.emailAddress}` > `${b.name}${b.emailAddress}`
                ? 1
                : -1
            )
            .map((x, i) => (
              <option key={i} value={x.id}>
                {x.name} {x.status === XadminStatus.INACTIVE && `(INACTIVO)`}{' '}
                <span style={{ color: '#aaa', fontSize: '0.8rem' }}>
                  {x.emailAddress}
                </span>
              </option>
            ))}
        </select>
      </div>
      <div className="field">
        <div className="label">Categoría(s)*</div>
        <select
          size={3}
          multiple
          {...register('collectionIds')}
          disabled={disabled}
        >
          {collections
            .sort((a, b) =>
              `${a.name}${a.slug}` > `${b.name}${b.slug}` ? 1 : -1
            )
            .map((x, i) => (
              <option key={i} value={x.id}>
                {`${x.name}${x.isPrivate ? ' (INACTIVO)' : ''}`}{' '}
                <span style={{ color: '#aaa', fontSize: '0.8rem' }}>
                  {x.slug}
                </span>
              </option>
            ))}
        </select>
        {errors.collectionIds && (
          <div className="validation">Campo requerido</div>
        )}
      </div>
      <div className="field">
        <div className="label">Descripción</div>
        <Editor
          initialValue={defaultValues?.shortDescription}
          apiKey={tinymceApiKey}
          onEditorChange={(content) => {
            setValue('shortDescription', content as never);
          }}
          init={{
            height: tinymceHeight,
            menubar: false,
            toolbar: tinymceToolbar,
            plugins: 'link code',
            language: 'es',
          }}
          {...register('shortDescription', {
            required: 'Description is required',
          })}
        />
      </div>
      <div className="field">
        <div className="label">Cuerpo</div>
        <Editor
          initialValue={defaultValues?.body}
          apiKey={tinymceApiKey}
          onEditorChange={(content) => {
            setValue('body', content as never);
          }}
          init={{
            height: tinymceHeight,
            menubar: false,
            toolbar: tinymceToolbar,
            plugins: 'link code',
            language: 'es',
          }}
          {...register('body', { required: 'Description is required' })}
        />
      </div>
      <div className="field">
        <div className="label">Información nutricional</div>
        <Editor
          initialValue={defaultValues?.nutritionalInfo}
          apiKey={tinymceApiKey}
          onEditorChange={(content) => {
            setValue('nutritionalInfo', content as never);
          }}
          init={{
            height: tinymceHeight,
            menubar: false,
            toolbar: tinymceToolbar,
            plugins: 'link code',
            language: 'es',
          }}
          {...register('nutritionalInfo', {
            required: 'Description is required',
          })}
        />
        <div className="mx-3 mt-1" style={{ color: '#ff8000' }}>
          IMPORTANTE: editar el campo "Información nutricional" sólo atravéz del
          Código fuente (&lt;&gt;).
        </div>
      </div>
    </>
  );
}
