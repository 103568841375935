import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import { xcustomersQuery } from 'Customer/queries';
import CustomerListCard from './Card';

export default function CustomerList() {
  const { data, loading, error } = useQuery(xcustomersQuery, {
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const customers: any[] | undefined = data?.xcustomers.items;

  return (
    <>
      {customers && customers.length === 0 && (
        <Typography sx={{ color: 'text.secondary' }}>
          Aún no hay usuarios.
        </Typography>
      )}
      {customers &&
        customers
          .sort((a: any, b: any) =>
            a.firstName + a.lastName > b.firstName + b.lastName ? 1 : -1
          )
          .map((x: any, i: number) => {
            const { id, firstName, lastName } = x;
            return (
              <CustomerListCard
                key={i}
                id={id}
                firstName={firstName}
                lastName={lastName}
              />
            );
          })}
    </>
  );
}
