import { gql, useQuery } from '@apollo/client';
import { xcollectionsQuery } from 'Category/queries';

// query
const suppliersQuery = gql`
  query SuppliersQuery {
    suppliers {
      id
      firstName
      emailAddress
      customFields {
        status
      }
    }
  }
`;

//
// utility
//
export default function useSuppliersAndCollections() {
  const {
    loading: suppliersLoading,
    error: suppliersError,
    data: suppliersData,
  } = useQuery(suppliersQuery, {
    fetchPolicy: 'no-cache',
  });
  const {
    loading: collectionsLoading,
    error: collectionsError,
    data: collectionsData,
  } = useQuery(xcollectionsQuery, {
    fetchPolicy: 'no-cache',
  });
  const loading = suppliersLoading || collectionsLoading;
  const isError = suppliersError || collectionsError;
  const data = {
    suppliers: suppliersData,
    collections: collectionsData,
  };
  return { loading, isError, data };
}
