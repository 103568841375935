import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useAppDispatch } from 'Common/store/hooks';
import { xproductsQuery } from 'Product/queries';
import { setDeletionAlert } from 'Product/store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// config
const storefrontBaseUrl = process.env.REACT_APP_STOREFRONT_BASE_URL;

// mutation
const deleteXproductMutation = gql`
  mutation DeleteXproduct($id: ID!) {
    deleteXproduct(id: $id) {
      result
      message
    }
  }
`;

// props
interface Props {
  id: string;
  title: string;
  slug: string;
  status: 'ACTIVE' | 'INACTIVE' | 'UNAVAILABLE';
}

/**
 * Product list item component.
 */
export default function ProductListCard({ id, title, slug, status }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [globalError, setGlobalError] = useState(false);
  const [deleteProduct, { loading, error }] = useMutation(
    deleteXproductMutation,
    {
      refetchQueries: [xproductsQuery],
    }
  );
  const viewUrl = new URL(`productos/${slug}`, storefrontBaseUrl).toString();

  function handleUpdateClick() {
    navigate(`editar/${id}`);
  }

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    if (loading) {
      return;
    }
    setDialogOpen(false);
    setGlobalError(false);
  }

  async function handleProductDelete() {
    if (loading) {
      return;
    }
    setGlobalError(false);
    try {
      const response = await deleteProduct({
        variables: { id },
      });
      const result = response.data?.deleteXproduct?.result;
      const message = response.data?.deleteXproduct?.message;
      if (result !== 'DELETED') {
        console.error(message); // TODO: dev only
        setGlobalError(true);
      } else {
        dispatch(setDeletionAlert(true));
        handleDialogClose();
      }
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <div className="row item">
        <div className="col-lg-10 padding_no">
          <div className="content">
            <span className={`material-icons ${status.toLowerCase()}`}>
              fiber_manual_record
            </span>
            {title}
          </div>
        </div>
        <div className="col-lg-2 padding_no">
          <div className="buttons">
            <a href={viewUrl} target="_blank" rel="noreferrer">
              <span className="material-icons">visibility</span>
            </a>
            <button type="button">
              <span className="material-icons" onClick={handleUpdateClick}>
                edit
              </span>
            </button>
            <button type="button" onClick={handleDialogOpen}>
              <span className="material-icons">delete</span>
            </button>
          </div>
        </div>
      </div>
      {dialogOpen && (
        <div className="grayout">
          <div className="popup">
            <div className="close">
              <button type="button" onClick={handleDialogClose}>
                <span className="material-icons">close</span>
              </button>
            </div>
            <h1>¿Quieres eliminar este ítem?</h1>
            {(error || globalError) && (
              <Box className="success" sx={{ bgcolor: 'error.main' }}>
                <p>Ha ocurrido un error. Inténtalo de nuevo</p>
              </Box>
            )}
            <form>
              <div className="buttons">
                <button type="button" onClick={handleProductDelete}>
                  Sí, eliminar ítem
                </button>
                <button
                  className="cancel"
                  type="button"
                  onClick={handleDialogClose}
                >
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
