import { useQuery } from '@apollo/client';
import ProductListCard from './Card';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import { Typography } from '@mui/material';
import { xproductsQuery } from 'Product/queries';
import ProductStatus from 'Product/types/Status';

interface ProductItem {
  id: string;
  name: string;
  slug: string;
  status: ProductStatus;
}

export default function ProductList() {
  const { loading, error, data } = useQuery(xproductsQuery, {
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const products: ProductItem[] | undefined = data?.xproducts.items.map(
    (x: any) => ({
      ...x,
      status: x.customFields.status as ProductStatus,
    })
  );

  if (!products || products.length === 0) {
    return (
      <Typography sx={{ color: 'text.secondary' }}>
        Aún no hay productos.
      </Typography>
    );
  }

  return (
    <>
      {products
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((x, i) => (
          <ProductListCard
            key={i}
            id={x.id}
            title={x.name}
            status={x.status}
            slug={x.slug}
          />
        ))}
    </>
  );
}
