import useIsManagerOrSuperAdmin from 'Auth/utils/useIsManager';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import CommonNotFound from 'Common/components/NotFound';
import { Route, Routes } from 'react-router-dom';
import CustomerView from '../View';
import CustomerCreate from '../Create';
import CustomerUpdate from '../Update';

export default function CustomerMain() {
  const { loading, error, data: isManager } = useIsManagerOrSuperAdmin();

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  return (
    <Routes>
      {isManager && (
        <>
          <Route path="/" element={<CustomerView />} />
          <Route path="/agregar" element={<CustomerCreate />} />
          <Route path="/editar/:id" element={<CustomerUpdate />} />
        </>
      )}
      <Route path="*" element={<CommonNotFound />} />
    </Routes>
  );
}
