import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';

// state definition
interface OrderState {
  cancellationAlert: boolean;
  fulfillmentAlert: boolean;
}

// initial state
const initialState: OrderState = {
  cancellationAlert: false,
  fulfillmentAlert: false,
};

// slice
export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCancellationAlert: (state, action: PayloadAction<boolean>) => {
      state.cancellationAlert = action.payload;
    },
    setFulfillmentAlert: (state, action: PayloadAction<boolean>) => {
      state.fulfillmentAlert = action.payload;
    },
  },
});

// actions
export const { setCancellationAlert, setFulfillmentAlert } = orderSlice.actions;

// selectors
export const selectCancellationAlert = (state: RootState) =>
  state.order.cancellationAlert;
export const selectFulfillmentAlert = (state: RootState) =>
  state.order.fulfillmentAlert;

// reducer
export default orderSlice.reducer;
