import { z } from 'zod';

export const productVariantSchema = z.object({
  id: z.string().optional(),
  size: z
    .number({
      invalid_type_error: 'Ingresa un número',
      required_error: 'Campo requerido',
    })
    .positive('Debe ser positivo')
    .int('Debe ser entero'),
  price: z
    .number({
      invalid_type_error: 'Ingresa un número',
      required_error: 'Campo requerido',
    })
    .positive('Debe ser positivo'),
  enabled: z.boolean(),
});

type ProductVariantSchema = z.infer<typeof productVariantSchema>;
export default ProductVariantSchema;
