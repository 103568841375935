import useRole from 'Auth/utils/useRole';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import CommonNotFound from 'Common/components/NotFound';
import { Route, Routes } from 'react-router-dom';
import OrderView from '../View';
import AuthRole from 'Auth/types/Role';
import OrderDetails from '../Details';

export default function OrderMain() {
  const { loading, error, data: role } = useRole();

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  return (
    <Routes>
      {role && (
        <>
          <Route path="/" element={<OrderView />} />
          {role === AuthRole.MANAGER && (
            <Route path=":customerId" element={<OrderView />} />
          )}
          <Route path="editar/:orderId" element={<OrderDetails />} />
        </>
      )}
      <Route path="*" element={<CommonNotFound />} />
    </Routes>
  );
}
