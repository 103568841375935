import { gql, useMutation } from '@apollo/client';
import CommonDeletionPopup from 'Common/components/DeletionPopup';
import { useAppDispatch } from 'Common/store/hooks';
import { xcustomersQuery } from 'Customer/queries';
import { setDeletionAlert } from 'Customer/store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// mutation
const deleteXcustomerMutation = gql`
  mutation DeleteXcustomer($id: ID!) {
    deleteXcustomer(id: $id) {
      result
      message
    }
  }
`;

// props
interface Props {
  id: string;
  firstName: string;
  lastName: string;
}

//
// component
//
export default function CustomerListCard({ id, firstName, lastName }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteXcustomer, { loading, error }] = useMutation(
    deleteXcustomerMutation,
    {
      refetchQueries: [xcustomersQuery],
    }
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [globalError, setGlobalError] = useState(false);
  const isError = globalError || !!error;

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    if (loading) {
      return;
    }
    setDialogOpen(false);
    setGlobalError(false);
  }

  function handleAddressClick() {
    navigate(`/direcciones-de-envio/${id}`);
  }

  function handleOrderClick() {
    navigate(`/ordenes/${id}`);
  }

  function handleUpdateClick() {
    navigate(`editar/${id}`);
  }

  async function handleDelete() {
    if (loading) {
      return;
    }
    setGlobalError(false);
    try {
      const response = await deleteXcustomer({
        variables: { id },
      });
      const result = response.data?.deleteXcustomer?.result;
      const message = response.data?.deleteXcustomer?.message;
      if (result !== 'DELETED') {
        console.error(message); // TODO: dev only
        setGlobalError(true);
      } else {
        dispatch(setDeletionAlert(true));
        handleDialogClose();
      }
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <div className="row item">
        <div className="col-lg-10 padding_no">
          <div className="content">{`${firstName} ${lastName}`}</div>
        </div>
        <div className="col-lg-2 padding_no">
          <div className="buttons">
            <button type="button" onClick={handleAddressClick}>
              <span className="material-icons">home</span>
            </button>
            <button type="button" onClick={handleOrderClick}>
              <span className="material-icons">shopping_cart</span>
            </button>
            <button type="button" onClick={handleUpdateClick}>
              <span className="material-icons">edit</span>
            </button>
            <button type="button" onClick={handleDialogOpen}>
              <span className="material-icons">delete</span>
            </button>
          </div>
        </div>
      </div>
      {dialogOpen && (
        <CommonDeletionPopup
          isError={isError}
          onClose={handleDialogClose}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
