import { gql, useApolloClient, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { meQuery } from 'Common/queries';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import ReCaptcha from 'react-google-recaptcha';
import { Checkbox, FormControlLabel } from '@mui/material';

// config
const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

// mutation
const loginMutation = gql`
  mutation Login(
    $email: String!
    $password: String!
    $captchaToken: String!
    $rememberMe: Boolean
  ) {
    authenticate(
      input: {
        captcha: {
          username: $email
          password: $password
          captchaToken: $captchaToken
        }
      }
      rememberMe: $rememberMe
    ) {
      __typename
      ... on CurrentUser {
        identifier
      }
    }
  }
`;

// schema
const schema = z.object({
  email: z.string().nonempty().email(),
  password: z.string().nonempty(),
  captchaToken: z.string().nonempty(),
  rememberMe: z.boolean().optional(),
});
type Schema = z.infer<typeof schema>;

//
// component
//
export default function CommonLogin() {
  const client = useApolloClient();
  const [login, { data, loading, error }] = useMutation(loginMutation);
  const [invalidCreds, setInvalidCreds] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const captchaToken = watch('captchaToken');

  function showPassword() {
    setPasswordShown(true);
  }
  function hidePassword() {
    setPasswordShown(false);
  }

  useEffect(() => {
    if (error) {
      setErrorOccurred(true);
    }
  }, [error]);

  useEffect(() => {
    switch (data?.authenticate?.__typename) {
      case 'CurrentUser': {
        client.writeQuery({
          query: meQuery,
          data: {
            me: {
              identifier: data.authenticate.identifier,
            },
          },
        });
        break;
      }
      case 'InvalidCredentialsError': {
        setInvalidCreds(true);
        break;
      }
      case undefined: {
        break;
      }
      default:
        setErrorOccurred(true);
    }
  }, [data, client]);

  async function _handleSubmit(data: Schema) {
    setErrorOccurred(false);
    setInvalidCreds(false);
    try {
      await login({
        variables: {
          ...data,
        },
      });
    } catch (err) {
      console.error(err); // TODO: dev only
      setErrorOccurred(true);
    }
  }

  function handleCaptchaComplete(token: string | null) {
    token && setValue('captchaToken', token);
  }

  function renderEmailError() {
    switch (errors['email']?.type) {
      case 'too_small': {
        return <div className="validation">Campo requerido</div>;
      }
      case 'invalid_string': {
        return <div className="validation">Correo electrónico inválido</div>;
      }
      default:
        return null;
    }
  }

  return (
    <div className="screen">
      <div className="box">
        <h1>Iniciar sesión</h1>
        {errorOccurred && (
          <div className="mb-4 text-danger">
            Ha ocurrido un error. Inténtelo de nuevo
          </div>
        )}
        <form onSubmit={handleSubmit(_handleSubmit)}>
          <div className="field">
            <div className="label">Correo electrónico*</div>
            <input disabled={loading} {...register('email')} />
            {renderEmailError()}
            {invalidCreds && (
              <div className="validation">Correo electrónico inválido</div>
            )}
          </div>
          <div className="field" style={{ marginBottom: '1rem' }}>
            <div className="label">Contraseña*</div>
            <input
              type={passwordShown ? 'text' : 'password'}
              disabled={loading}
              {...register('password')}
            />
            {errors.password && (
              <div className="validation">Campo requerido</div>
            )}
            {invalidCreds && (
              <div className="validation">Contraseña inválida</div>
            )}
            <div className="visibility">
              {passwordShown ? (
                <button type="button" onClick={hidePassword}>
                  <span className="material-icons">visibility_off</span>
                </button>
              ) : (
                <button type="button" onClick={showPassword}>
                  <span className="material-icons">visibility</span>
                </button>
              )}
            </div>
          </div>
          <div className="field">
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  disableRipple
                  {...register('rememberMe')}
                />
              }
              label="Recuérdame"
            />
          </div>
          <div className="field">
            {sitekey ? (
              <ReCaptcha sitekey={sitekey} onChange={handleCaptchaComplete} />
            ) : captchaToken ? (
              <div
                style={{
                  textTransform: 'uppercase',
                }}
              >
                Simulated.
              </div>
            ) : (
              <button
                type="button"
                onClick={() => handleCaptchaComplete('dummy-token')}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                Simulate Captcha Success
              </button>
            )}
          </div>
          <div className="submit">
            <button type="submit" disabled={loading}>
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
