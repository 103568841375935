import { useQuery } from '@apollo/client';
import CategoryListCard from './Card';
import { xcollectionsQuery } from 'Category/queries';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CategoryStatus from 'Category/types/Status';
import { Typography } from '@mui/material';

export default function CategoryList() {
  const { loading, error, data } = useQuery(xcollectionsQuery, {
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const categories: any[] | undefined = data?.xcollections.items;

  if (!categories || categories.length === 0) {
    return (
      <Typography sx={{ color: 'text.secondary' }}>
        Aún no hay categorías.
      </Typography>
    );
  }

  return (
    <>
      {categories
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((x) => (
          <CategoryListCard
            key={x.id}
            id={x.id}
            name={x.name}
            slug={x.slug}
            status={
              x.isPrivate ? CategoryStatus.INACTIVE : CategoryStatus.ACTIVE
            }
          />
        ))}
    </>
  );
}
