import { z } from 'zod';

export const addressBasicsSchema = z.object({
  firstName: z.string().nonempty('Campo requerido'),
  lastName: z.string().nonempty('Campo requerido'),
  province: z.string().nonempty('Campo requerido'),
  city: z.string().nonempty('Campo requerido'),
  streetLine1: z.string().nonempty('Campo requerido'),
  phoneNumber: z.string().nonempty('Campo requerido'),
  postalCode: z
    .string()
    .nonempty('Campo requerido')
    .regex(/^[0-9]{5}$/, 'Código postal inválido'),
  additionalInfo: z.string().optional(),
});

type AddressBasics = z.infer<typeof addressBasicsSchema>;
export default AddressBasics;
