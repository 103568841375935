import { Link, Typography } from '@mui/material';

export default function CommonErrorScreen() {
  return (
    <Typography sx={{ color: 'error.main' }}>
      Ha ocurrido un error.{' '}
      <Link
        component="button"
        color="error"
        onClick={() => window.location.reload()}
      >
        Inténtalo de nuevo
      </Link>
    </Typography>
  );
}
