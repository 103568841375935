import { ZodString, z } from 'zod';

export function makeCustomerPasswordSchema(required = false): ZodString {
  const lengthRegexp = required ? /.{8,}/ : /^$|.{8,}/;
  const upperCaseRegexp = required ? /[A-Z]+/ : /^$|[A-Z]+/;
  const lowerCaseRegexp = required ? /[a-z]+/ : /^$|[a-z]+/;
  const numberRegexp = required ? /[0-9]+/ : /^$|[0-9]+/;
  return z
    .string()
    .min(required ? 1 : 0, 'Campo requerido')
    .regex(lengthRegexp, 'Mínimo 8 caracteres')
    .regex(upperCaseRegexp, 'Por lo menos una letra mayúscula')
    .regex(lowerCaseRegexp, 'Por lo menos una letra minúscula')
    .regex(numberRegexp, 'Por lo menos un número');
}
