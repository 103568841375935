import { z } from 'zod';
import ProductStatus from './Status';

export const productBasicsFormSchema = z.object({
  status: z.nativeEnum(ProductStatus),
  name: z
    .string()
    .nonempty('Campo requerido')
    .refine(
      (value) => {
        return value && value.trim() !== '';
      },
      {
        message: 'Campo requerido',
      }
    )
    .transform((value) => {
      return value.trim();
    }),
  slug: z
    .string()
    .nonempty('Campo requerido')
    .refine(
      (value) => {
        return value && value.trim() !== '';
      },
      {
        message: 'Campo requerido',
      }
    )
    .transform((value) => {
      return value.trim();
    }),
  shortDescription: z.string().optional(),
  body: z.string().optional(),
  metaTitle: z
    .string()
    .optional()
    .transform((value) => {
      return value?.trim();
    }),
  metaDescription: z
    .string()
    .optional()
    .transform((value) => {
      return value?.trim();
    }),
  nutritionalInfo: z.string().optional(),
  collectionIds: z.string().array().min(1),
  supplierId: z.string(),
});

type ProductBasicsFormSchema = z.infer<typeof productBasicsFormSchema>;
export default ProductBasicsFormSchema;
