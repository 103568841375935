import { ZodObject, z } from 'zod';

type ProductFileSchema = {
  file?: any;
};

export function makeFileSchema(required = false): ZodObject<ProductFileSchema> {
  return z.object({
    file: z.any().refine((x) => (required ? !!x : true)),
  });
}

export default ProductFileSchema;
