import CommonMenu from 'Common/components/Menu';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

export default function CommonLayout({ children }: Props) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 padding_no">
          <div className="left">
            <CommonMenu />
          </div>
        </div>
        <div className="col-lg-9 padding_no">
          <div className="right">{children}</div>
        </div>
      </div>
    </div>
  );
}
