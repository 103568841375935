import { Box, Typography } from '@mui/material';
import OrderItem from 'Order/types/Item';

interface Props {
  order: OrderItem;
}

export default function OrderDetailsAddress({ order }: Props) {
  const {
    country,
    province,
    city,
    streetLine1,
    postalCode,
    phoneNumber,
    customFields,
  } = order.shippingAddress;
  const firstName = customFields?.firstName;
  const lastName = customFields?.lastName;
  const additionalInfo = customFields?.additionalInfo;
  return (
    <>
      <h2>Detalles de envío</h2>
      {!!streetLine1 ? (
        <Box mt="20px">
          <div>
            {firstName} {lastName}
          </div>
          <div>
            {streetLine1}
            {additionalInfo && `, ${additionalInfo}`}
          </div>
          <div>
            {postalCode && `${postalCode}, `}
            {city && `${city}, `}
            {province && `${province}, `}
            {country}
          </div>
          <div>{phoneNumber}</div>
        </Box>
      ) : (
        <Typography sx={{ color: 'red' }}>
          No hay dirección de envío.
        </Typography>
      )}
    </>
  );
}
