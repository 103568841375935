interface Props {
  orderState: string;
}

export default function OrderStatus({ orderState }: Props) {
  switch (orderState) {
    case 'PaymentSettled': {
      return (
        <button className="pending" type="button" disabled>
          APROBADO
        </button>
      );
    }
    case 'Cancelled': {
      return (
        <button className="canceled" type="button" disabled>
          CANCELADO
        </button>
      );
    }
    case 'PartiallyShipped': {
      return (
        <button className="pending" type="button" disabled>
          ENVIADO PARCIALMENTE
        </button>
      );
    }
    case 'Shipped': {
      return (
        <button className="done" type="button" disabled>
          ENVIADO
        </button>
      );
    }
    default:
      return null;
  }
}
