import OrderItem from 'Order/types/Item';
import OrderListCard from './Card';
import { Typography } from '@mui/material';

interface Props {
  items: OrderItem[];
}

export default function OrderList({ items }: Props) {
  if (items.length === 0) {
    return (
      <Typography sx={{ color: 'text.secondary' }}>
        Aún no hay ordenes.
      </Typography>
    );
  }
  return (
    <>
      {items
        .sort((a, b) => (a.orderPlacedAt < b.orderPlacedAt ? 1 : -1))
        .map((x, i) => (
          <OrderListCard key={i} item={x} />
        ))}
    </>
  );
}
