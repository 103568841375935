import { zodResolver } from '@hookform/resolvers/zod';
import CategoryFormSchema, {
  categoryFormSchema,
} from 'Category/types/FormSchema';
import { Controller, useForm } from 'react-hook-form';
import CategoryFormBasics from '../FormBasics';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CommonCancelButton from '../../../Common/components/CancelButton';

interface Props {
  defaultValues: CategoryFormSchema;
  disabled?: boolean;
  onSubmit?: (data: CategoryFormSchema) => void;
  defaultThumbnailUrl?: string;
}

export default function CategoryUpdateForm({
  defaultValues,
  disabled = false,
  onSubmit = () => {},
  defaultThumbnailUrl,
}: Props) {
  const [thumbnailFieldVisible, setThumbnailFieldVisible] = useState(true); // somewhat hacky
  const [thumbnailUrl, setThumbnailUrl] = useState(defaultThumbnailUrl);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
    setValue,
  } = useForm<CategoryFormSchema>({
    resolver: zodResolver(categoryFormSchema),
    defaultValues,
  });

  // somewhat hacky
  useEffect(() => {
    if (!thumbnailFieldVisible) {
      setThumbnailFieldVisible(true);
    }
  }, [thumbnailFieldVisible]);

  useEffect(() => {
    // @ts-ignore (TODO)
    const sub = watch((data: CategoryFormSchema) => {
      const { deleteExistingThumbnail, thumbnail } = data;
      if (thumbnail) {
        setThumbnailUrl(window.URL.createObjectURL(thumbnail));
      } else if (deleteExistingThumbnail === 'true') {
        setThumbnailUrl(undefined);
      }
    });
    return () => {
      // @ts-ignore (not sure why `watch` returns `never`)
      sub.unsubscribe();
    };
  }, [watch]);

  function handleThumbnailRemove() {
    resetField('thumbnail', {
      defaultValue: undefined,
    });
    setThumbnailFieldVisible(false);
    setValue('deleteExistingThumbnail', 'true' as never);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <CategoryFormBasics
        // @ts-ignore
        register={register}
        errors={errors}
        disabled={disabled}
        watch={watch}
        // @ts-ignore
        setValue={setValue}
      />
      <input type="hidden" {...register('deleteExistingThumbnail')} />
      <div className="field">
        <div className="label">Miniatura</div>
        {thumbnailFieldVisible && (
          <Controller
            control={control}
            name="thumbnail"
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <input
                  type="file"
                  accept="image/*"
                  disabled={disabled}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files && event.target.files[0]);
                  }}
                  {...field}
                />
              );
            }}
          />
        )}
        {thumbnailUrl && (
          <div className="thumbnail">
            <div className="remove">
              <button type="button" onClick={handleThumbnailRemove}>
                <span className="material-icons">remove_circle</span>
              </button>
            </div>
            <img src={thumbnailUrl} alt="Miniatura" />
          </div>
        )}
      </div>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <div className="submit">
          <button type="submit" disabled={disabled}>
            Guardar
          </button>
        </div>
        <CommonCancelButton disabled={disabled} />
      </Box>
    </form>
  );
}
