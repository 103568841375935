import { useAppDispatch, useAppSelector } from 'Common/store/hooks';
import { selectCancellationAlert, setCancellationAlert } from 'Order/store';
import { useEffect, useState } from 'react';

export default function OrderViewAlert() {
  const dispatch = useAppDispatch();
  const cancellationAlert = useAppSelector(selectCancellationAlert);
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if (cancellationAlert) {
      setShown(true);
      dispatch(setCancellationAlert(false));
    }
  }, [dispatch, cancellationAlert]);

  if (shown) {
    return (
      <div className="success">
        <p>La orden ha sido cancelada.</p>
      </div>
    );
  }

  return null;
}
