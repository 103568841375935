import dayjs from 'dayjs';
import OrderOutput from 'Order/types/Output';
import OrderStatus from '../Status';
import OrderDetailsPackage from './Package';
import formatPrice from 'Order/utils/formatPrice';
import OrderDetailsAddress from './Address';
import { useAppDispatch, useAppSelector } from 'Common/store/hooks';
import { useEffect, useState } from 'react';
import { selectFulfillmentAlert, setFulfillmentAlert } from 'Order/store';
import OrderPayment from 'Order/types/Payment';

// props
interface Props {
  orderOutput: OrderOutput;
  orderPayments?: OrderPayment[];
}

//
// component
//
export default function OrderDetailsView({
  orderOutput,
  orderPayments,
}: Props) {
  const dispatch = useAppDispatch();
  const fulfillmentAlert = useAppSelector(selectFulfillmentAlert);
  const { order, packages } = orderOutput;
  const { code, state, orderPlacedAt } = order;
  const [alertShown, setAlertShown] = useState(false);
  const subTotal = packages.reduce((sum: number, _package) => {
    const packagePrice = _package.lines.reduce((acc: number, x) => {
      return acc + x.linePrice * x.quantity;
    }, 0);
    return sum + packagePrice;
  }, 0);
  const total = subTotal; // TODO: shipping cost etc

  useEffect(() => {
    if (fulfillmentAlert) {
      setAlertShown(true);
      dispatch(setFulfillmentAlert(false));
    }
  }, [dispatch, fulfillmentAlert]);

  return (
    <>
      <h1>Orden (Nº {code})</h1>
      {alertShown && (
        <div className="success">
          <p>El número de seguimiento ha sido guardado.</p>
        </div>
      )}
      <p>
        Fecha: {dayjs(orderPlacedAt).format('DD-MMM-YYYY HH:mm')}
        <br />
        Estado: <OrderStatus orderState={state} />
      </p>
      <h2>Detalles del pago</h2>
      <p>
        Subtotal: {formatPrice(subTotal)}
        <br />
        <span>Envío: 0,00 €</span>
        <br />
        Total: {formatPrice(total)}
      </p>
      {orderPayments
        ?.filter((x) => !!x.cardBrand && !!x.cardLast4)
        .map((x, i) => (
          <p key={i}>
            {x.cardBrand?.toUpperCase()} **** **** **** {x.cardLast4}
          </p>
        ))}
      <OrderDetailsAddress order={order} />
      <p>
        Envío Express
        <br />
        Entrega en 2-5 días hábiles
      </p>
      {packages.map((x, i) => (
        <OrderDetailsPackage key={i} orderPackage={x} order={order} />
      ))}
    </>
  );
}
