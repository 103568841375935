import { gql, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { useAppDispatch } from 'Common/store/hooks';
import { xcustomersQuery } from 'Customer/queries';
import { setUpdatingAlert } from 'Customer/store';
import CustomerBasics, { customerBasicsSchema } from 'Customer/types/Basics';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonCancelButton from '../../../Common/components/CancelButton';

// internal utility
enum BooleanLiteral {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

// mutation
const updateXcustomerMutation = gql`
  mutation UpdateXcustomer(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $subscription: Boolean!
  ) {
    updateXcustomer(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
      }
      subscription: $subscription
    ) {
      __typename
      ... on Customer {
        id
        firstName
        lastName
        emailAddress
      }
      ... on EmailAddressConflictError {
        message
        errorCode
      }
    }
  }
`;

// props
interface Props {
  id: string;
  defaultValues: CustomerBasics;
}

//
// component
//
export default function CustomerUpdateForm({ id, defaultValues }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CustomerBasics>({
    resolver: zodResolver(customerBasicsSchema),
    defaultValues,
  });
  const [updateXcustomer, { loading, error }] = useMutation(
    updateXcustomerMutation,
    {
      refetchQueries: [xcustomersQuery],
    }
  );
  const [globalError, setGlobalError] = useState(false);
  const disabled = loading;
  const isError = !!error || globalError;

  async function _handleSubmit(data: CustomerBasics) {
    setGlobalError(false);
    try {
      const response = await updateXcustomer({
        variables: {
          id,
          ...data,
        },
      });
      switch (response.data?.updateXcustomer.__typename) {
        case 'Customer': {
          dispatch(setUpdatingAlert(true));
          navigate('..');
          break;
        }
        case 'EmailAddressConflictError': {
          setError('emailAddress', {
            message: 'El usuario con este correo electrónico ya existe.',
          });
          break;
        }
        default: {
          console.error(response); // TODO: dev only
          setGlobalError(true);
        }
      }
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <h1>Editar usuario</h1>
      {isError && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      <form onSubmit={handleSubmit(_handleSubmit)} noValidate>
        <div className="field">
          <div className="label">Nombre*</div>
          <input type="text" {...register('firstName')} disabled={disabled} />
          {errors.firstName && (
            <div className="validation">{errors.firstName.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Apellidos*</div>
          <input type="text" {...register('lastName')} disabled={disabled} />
          {errors.lastName && (
            <div className="validation">{errors.lastName.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Correo electrónico*</div>
          <input
            type="email"
            {...register('emailAddress')}
            disabled={disabled}
          />
          {errors.emailAddress && (
            <div className="validation">{errors.emailAddress.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Suscripción*</div>
          <Controller
            control={control}
            name="subscription"
            render={({ field: { value, onChange } }) => {
              const _value =
                value === true
                  ? BooleanLiteral.TRUE
                  : value === false
                  ? BooleanLiteral.FALSE
                  : undefined;
              return (
                <select
                  size={2}
                  value={_value}
                  disabled={disabled}
                  onChange={(e) =>
                    onChange(e.target.value === BooleanLiteral.TRUE)
                  }
                >
                  <option value={BooleanLiteral.TRUE}>Sí</option>
                  <option value={BooleanLiteral.FALSE}>No</option>
                </select>
              );
            }}
          />
        </div>
        {errors.subscription && (
          <div className="validation">{errors.subscription.message}</div>
        )}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <div className="submit">
            <button type="submit" disabled={disabled}>
              Guardar
            </button>
          </div>
          <CommonCancelButton disabled={disabled} />
        </Box>
      </form>
    </>
  );
}
