import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import AddressBasics, { addressBasicsSchema } from 'Address/types/Basics';
import { useForm } from 'react-hook-form';
import CommonCancelButton from '../../../Common/components/CancelButton';

interface Props {
  defaultValues?: AddressBasics;
  disabled?: boolean;
  isError?: boolean;
  onSubmit?: (data: AddressBasics) => void;
}

export default function AddressForm({
  defaultValues,
  disabled = false,
  isError = false,
  onSubmit = () => {},
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressBasics>({
    resolver: zodResolver(addressBasicsSchema),
    defaultValues,
  });

  return (
    <>
      {isError && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <div className="label">Nombre*</div>
          <input type="text" {...register('firstName')} disabled={disabled} />
          {errors.firstName && (
            <div className="validation">{errors.firstName.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Apellidos*</div>
          <input type="text" {...register('lastName')} disabled={disabled} />
          {errors.lastName && (
            <div className="validation">{errors.lastName.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">País</div>
          <input type="text" disabled value="España" />
        </div>
        <div className="field">
          <div className="label">Provincia*</div>
          <select size={5} {...register('province')} disabled={disabled}>
            <option value="A Coruña">A Coruña</option>
            <option value="Álava">Álava</option>
            <option value="Albacete">Albacete</option>
            <option value="Alicante">Alicante</option>
            <option value="Almería">Almería</option>
            <option value="Asturias">Asturias</option>
            <option value="Ávila">Ávila</option>
            <option value="Badajoz">Badajoz</option>
            <option value="Baleares">Baleares</option>
            <option value="Barcelona">Barcelona</option>
            <option value="Burgos">Burgos</option>
            <option value="Cáceres">Cáceres</option>
            <option value="Cádiz">Cádiz</option>
            <option value="Cantabria">Cantabria</option>
            <option value="Castellón">Castellón</option>
            <option value="Ciudad Real">Ciudad Real</option>
            <option value="Córdoba">Córdoba</option>
            <option value="Cuenca">Cuenca</option>
            <option value="Girona">Girona</option>
            <option value="Granada">Granada</option>
            <option value="Guadalajara">Guadalajara</option>
            <option value="Gipuzkoa">Gipuzkoa</option>
            <option value="Huelva">Huelva</option>
            <option value="Huesca">Huesca</option>
            <option value="Jaén">Jaén</option>
            <option value="La Rioja">La Rioja</option>
            <option value="Las Palmas">Las Palmas</option>
            <option value="León">León</option>
            <option value="Lérida">Lérida</option>
            <option value="Lugo">Lugo</option>
            <option value="Madrid">Madrid</option>
            <option value="Málaga">Málaga</option>
            <option value="Murcia">Murcia</option>
            <option value="Navarra">Navarra</option>
            <option value="Ourense">Ourense</option>
            <option value="Palencia">Palencia</option>
            <option value="Pontevedra">Pontevedra</option>
            <option value="Salamanca">Salamanca</option>
            <option value="Segovia">Segovia</option>
            <option value="Sevilla">Sevilla</option>
            <option value="Soria">Soria</option>
            <option value="Tarragona">Tarragona</option>
            <option value="Santa Cruz de Tenerife">
              Santa Cruz de Tenerife
            </option>
            <option value="Teruel">Teruel</option>
            <option value="Toledo">Toledo</option>
            <option value="Valencia">Valencia</option>
            <option value="Valladolid">Valladolid</option>
            <option value="Vizcaya">Vizcaya</option>
            <option value="Zamora">Zamora</option>
            <option value="Zaragoza">Zaragoza</option>
            <option value="Ceuta">Ceuta</option>
            <option value="Melilla">Melilla</option>
          </select>
          {errors.province && (
            <div className="validation">{errors.province.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Población*</div>
          <input type="text" {...register('city')} disabled={disabled} />
          {errors.city && (
            <div className="validation">{errors.city.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Dirección*</div>
          <input type="text" {...register('streetLine1')} disabled={disabled} />
          {errors.streetLine1 && (
            <div className="validation">{errors.streetLine1.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Información adicional</div>
          <input
            type="text"
            {...register('additionalInfo')}
            disabled={disabled}
          />
        </div>
        <div className="field">
          <div className="label">Código postal*</div>
          <input type="text" {...register('postalCode')} disabled={disabled} />
          {errors.postalCode && (
            <div className="validation">{errors.postalCode.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Teléfono*</div>
          <input type="tel" {...register('phoneNumber')} disabled={disabled} />
          {errors.phoneNumber && (
            <div className="validation">{errors.phoneNumber.message}</div>
          )}
        </div>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <div className="submit">
            <button type="submit" disabled={disabled}>
              Guardar
            </button>
          </div>
          <CommonCancelButton disabled={disabled} />
        </Box>
      </form>
    </>
  );
}
