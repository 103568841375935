import { useParams } from 'react-router-dom';
import OrderViewCustomer from './Customer';
import OrderViewManager from './Manager';
import useRole from 'Auth/utils/useRole';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import AuthRole from 'Auth/types/Role';
import OrderViewSupplier from './Supplier';

export default function OrderView() {
  const { customerId } = useParams();
  const { loading, error, data: role } = useRole();

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  if (role === AuthRole.MANAGER) {
    if (customerId) {
      return <OrderViewCustomer customerId={customerId} />;
    }
    return <OrderViewManager />;
  }

  if (role === AuthRole.SUPPLIER) {
    return <OrderViewSupplier />;
  }

  return null; // TODO: (maybe) error screen
}
