import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { getXadminsQuery, xadminMeQuery } from 'Admin/queries';
import { setDeletionAlert } from 'Admin/store';
import { useAppDispatch } from 'Common/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// mutation
const deleteXadminMutation = gql`
  mutation DeleteXadmin($id: ID!) {
    deleteXadmin(id: $id) {
      result
      message
    }
  }
`;

// props
interface Props {
  id: string;
  displayName: string;
  status: 'ACTIVE' | 'INACTIVE';
  type: 'MANAGER' | 'SUPPLIER';
}

//
// component
//
export default function AdminListCard({
  id,
  status,
  displayName,
  type,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteXadmin, { loading, error }] = useMutation(deleteXadminMutation, {
    refetchQueries: [getXadminsQuery],
  });
  const [globalError, setGlobalError] = useState(false);
  const { data: xadminMeData } = useQuery(xadminMeQuery);
  const [allowDelete, setAllowDelete] = useState(false);

  useEffect(() => {
    const userId = xadminMeData?.xadminMe?.id;
    setAllowDelete(userId !== id);
  }, [xadminMeData, id]);

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    if (loading) {
      return;
    }
    setDialogOpen(false);
    setGlobalError(false);
  }

  async function handleXadminDelete() {
    if (loading || !allowDelete) {
      return;
    }
    setGlobalError(false);
    try {
      const response = await deleteXadmin({
        variables: { id },
      });
      const result = response.data?.deleteXadmin?.result;
      const message = response.data?.deleteXadmin?.message;
      if (result !== 'DELETED') {
        console.error(message); // TODO: dev only
        setGlobalError(true);
      } else {
        dispatch(setDeletionAlert(true));
        handleDialogClose();
      }
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  function handleUpdateClick() {
    navigate(`editar/${id}`);
  }

  function renderType(): string {
    switch (type) {
      case 'MANAGER': {
        return 'Gestor';
      }
      case 'SUPPLIER': {
        return 'Proveedor';
      }
      default:
        return '';
    }
  }

  return (
    <>
      <div className="row item">
        <div className="col-lg-10 padding_no">
          <div className="content">
            <span
              className={`material-icons ${
                status === 'ACTIVE' ? 'active' : 'inactive'
              }`}
            >
              fiber_manual_record
            </span>
            <span className="column">{displayName}</span>
            {renderType()}
          </div>
        </div>
        <div className="col-lg-2 padding_no">
          <div className="buttons">
            <button type="button" onClick={handleUpdateClick}>
              <span className="material-icons">edit</span>
            </button>
            {allowDelete && (
              <button type="button" onClick={handleDialogOpen}>
                <span className="material-icons">delete</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {dialogOpen && (
        <div className="grayout">
          <div className="popup">
            <div className="close">
              <button type="button" onClick={handleDialogClose}>
                <span className="material-icons">close</span>
              </button>
            </div>
            <h1>¿Quieres eliminar este ítem?</h1>
            {(error || globalError) && (
              <Box className="success" sx={{ bgcolor: 'error.main' }}>
                <p>Ha ocurrido un error. Inténtalo de nuevo</p>
              </Box>
            )}
            <form>
              <div className="buttons">
                <button type="button" onClick={handleXadminDelete}>
                  Sí, eliminar ítem
                </button>
                <button
                  className="cancel"
                  type="button"
                  onClick={handleDialogClose}
                >
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
