import { gql } from '@apollo/client';

export const xproductsQuery = gql`
  query Xproducts {
    xproducts {
      items {
        id
        name
        slug
        customFields {
          status
        }
      }
    }
  }
`;

export const xproductQuery = gql`
  query Xproduct($id: ID!) {
    xproduct(id: $id) {
      id
      name
      slug
      collections {
        id
        isPrivate
      }
      customFields {
        metaTitle
        metaDescription
        shortDescription
        nutritionalInfo
        body
        supplier {
          id
          customFields {
            status
          }
        }
        status
        thumbnail {
          source
        }
      }
      assets {
        id
        source
      }
      variants {
        id
        price
        enabled
        customFields {
          weight
        }
      }
    }
  }
`;
