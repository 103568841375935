import { gql, useQuery } from '@apollo/client';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import CommonLoading from 'Common/components/Loading';
import OrderPackage from 'Order/types/Package';
import OrderItem from 'Order/types/Item';
import formatPrice from 'Order/utils/formatPrice';
import OrderDetailsPackageForm from './PackageForm';
import useRole from 'Auth/utils/useRole';
import formatWeight from 'Order/utils/formatWeight';

// query
const xorderShippingMethodsQuery = gql`
  query XorderShippingMethodsQuery {
    xorderShippingMethods {
      items {
        id
        name
        code
      }
    }
  }
`;

// props
interface Props {
  orderPackage: OrderPackage;
  order: OrderItem;
}

//
// component
//
export default function OrderDetailsPackage({ orderPackage, order }: Props) {
  const { name, supplier, lines } = orderPackage;
  const totalQuantity = lines.reduce((acc, x) => acc + x.quantity, 0);
  const { loading, error, data } = useQuery(xorderShippingMethodsQuery); // cached
  const { loading: roleLoading, error: roleError, data: role } = useRole();

  if (loading || roleLoading) {
    return <CommonLoading />;
  }

  if (error || roleError) {
    return <CommonErrorScreen />;
  }

  const shippingMethods = data?.xorderShippingMethods.items ?? [];

  return (
    <>
      <h2>Paquete {name}</h2>
      <p>
        Proveedor: {supplier.firstName}
        <br />
        Productos: {totalQuantity}
      </p>
      {lines
        .sort((a, b) =>
          a.productVariant.product.name > b.productVariant.product.name ? 1 : -1
        )
        .map((x, i) => (
          <p key={i}>
            {x.productVariant.product.name}{' '}
            {formatWeight(x.productVariant.customFields.weight)} x{x.quantity}
            <br />
            {formatPrice(x.linePrice * x.quantity)}
          </p>
        ))}
      {shippingMethods && role && (
        <OrderDetailsPackageForm
          orderPackage={orderPackage}
          order={order}
          shippingMethods={shippingMethods}
          role={role}
        />
      )}
    </>
  );
}
