import { Box } from '@mui/material';

interface Props {
  isError?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  trackingCode: string;
  shippingMethod: string | undefined;
}

export default function CommonOrderFulfillmentPopup({
  isError = false,
  onClose = () => {},
  onSubmit = () => {},
  trackingCode,
  shippingMethod: shippingMethodId,
}: Props) {
  return (
    <div className="grayout">
      <div className="popup">
        <div className="close">
          <button type="button" onClick={onClose}>
            <span className="material-icons">close</span>
          </button>
        </div>
        <h1>¿Quieres guardar este número de seguimiento?</h1>
        <Box>
          <p>
            <b>Empresa de envíos:</b> {shippingMethodId}
          </p>
          <p>
            <b>Número de seguimiento:</b> {trackingCode}
          </p>
        </Box>
        {isError && (
          <Box className="success" sx={{ bgcolor: 'error.main' }}>
            <p>Ha ocurrido un error. Inténtalo de nuevo</p>
          </Box>
        )}
        <div className="buttons">
          <button type="submit" onClick={onSubmit}>
            Sí, guardar número de seguimiento
          </button>
          <button className="cancel" type="button" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
}
