import { gql, useMutation } from '@apollo/client';
import { setDeletionAlert } from 'Address/store';
import AddressItem from 'Address/types/Item';
import CommonDeletionPopup from 'Common/components/DeletionPopup';
import { useAppDispatch } from 'Common/store/hooks';
import { xcustomerQuery } from 'Customer/queries';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// mutation
const deleteXaddressMutation = gql`
  mutation DeleteXaddress($id: ID!) {
    deleteXaddress(id: $id) {
      success
    }
  }
`;

// props
interface Props {
  item: AddressItem;
}

//
// component
//
export default function AddressListCard({ item }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    id,
    firstName,
    lastName,
    streetLine1,
    postalCode,
    city,
    province,
    additionalInfo,
    phoneNumber,
  } = item;
  const [deleteXaddress, { loading, error }] = useMutation(
    deleteXaddressMutation,
    {
      refetchQueries: [xcustomerQuery],
    }
  );
  const [globalError, setGlobalError] = useState(false);
  const isError = !!error || globalError;

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    if (loading) {
      return;
    }
    setDialogOpen(false);
    setGlobalError(false);
  }

  function handleUpdateClick() {
    navigate(`editar/${id}`);
  }

  async function handleDelete() {
    if (loading) {
      return;
    }
    setGlobalError(false);
    try {
      const response = await deleteXaddress({
        variables: { id },
      });
      if (!response.data?.deleteXaddress?.success) {
        console.error('success false'); // TODO: dev only
        setGlobalError(true);
      } else {
        dispatch(setDeletionAlert(true));
        handleDialogClose();
      }
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <div className="row item">
        <div className="col-lg-10 padding_no">
          <div className="content">
            {`${firstName} ${lastName}`}
            <br />
            {streetLine1}
            {additionalInfo ? `, ${additionalInfo}` : ''}
            <br />
            {postalCode}, {city}, {province}, España
            <br />
            {phoneNumber}
          </div>
        </div>
        <div className="col-lg-2 padding_no">
          <div className="buttons">
            <button type="button" onClick={handleUpdateClick}>
              <span className="material-icons">edit</span>
            </button>
            <button type="button" onClick={handleDialogOpen}>
              <span className="material-icons">delete</span>
            </button>
          </div>
        </div>
      </div>
      {dialogOpen && (
        <CommonDeletionPopup
          isError={isError}
          onClose={handleDialogClose}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
