import { gql } from '@apollo/client';

export const getXadminsQuery = gql`
  query Xadmins {
    xadmins {
      items {
        id
        firstName
        customFields {
          status
          type
        }
      }
    }
  }
`;

export const xadminMeQuery = gql`
  query XadminMeQuery {
    xadminMe {
      id
    }
  }
`;
