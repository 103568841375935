import { zodResolver } from '@hookform/resolvers/zod';
import XadminStatus from 'Admin/types/xadmin-status';
import ProductFormSchema, { productFormSchema } from 'Product/types/FormSchema';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ProductFormBasics from '../FormBasics';
import { useEffect, useState } from 'react';
import ProductFormImageInput from './ImageInput';
import { Box, FormControlLabel, Switch } from '@mui/material';
import CommonCancelButton from '../../../Common/components/CancelButton';

interface Props {
  collections: { id: string; name: string; isPrivate: boolean }[];
  suppliers: { id: string; name: string; status: XadminStatus }[];
  disabled?: boolean;
  onSubmit?: (data: ProductFormSchema) => void;
  defaultValues?: ProductFormSchema;
  defaultThumbnailUrl?: string;
}

export default function ProductForm({
  collections,
  suppliers,
  disabled = false,
  onSubmit = () => {},
  defaultValues,
  defaultThumbnailUrl,
}: Props) {
  const [thumbnailFieldVisible, setThumbnailFieldVisible] = useState(true); // somewhat hacky
  const [thumbnailUrl, setThumbnailUrl] = useState(defaultThumbnailUrl);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm<ProductFormSchema>({
    resolver: zodResolver(productFormSchema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          images:
            defaultValues.images.length > 0
              ? defaultValues.images.map((x) => ({
                  assetId: x.assetId,
                  source: x.source,
                }))
              : [
                  {
                    assetId: '' as never,
                    source: undefined,
                    file: undefined,
                  },
                ],
        }
      : undefined, // TODO: must be set empty variant & image fields by default
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variants',
  });
  const {
    fields: imageFields,
    append: imageAppend,
    remove: imageRemove,
    update: imageUpdate,
  } = useFieldArray({
    control,
    name: 'images',
  });

  useEffect(() => {
    const sub = watch((data: ProductFormSchema) => {
      const { deleteExistingThumbnail, thumbnail } = data;
      if (thumbnail) {
        setThumbnailUrl(window.URL.createObjectURL(thumbnail));
      } else if (deleteExistingThumbnail === 'true') {
        setThumbnailUrl(undefined);
      }
    });
    return () => {
      // @ts-ignore (not sure why `watch` returns `never`)
      sub.unsubscribe();
    };
  }, [watch]);

  // somewhat hacky
  useEffect(() => {
    if (!thumbnailFieldVisible) {
      setThumbnailFieldVisible(true);
    }
  }, [thumbnailFieldVisible]);

  function handleAppend() {
    append({
      size: '' as never,
      price: '' as never,
      enabled: true,
    });
  }
  function handleImageAppend() {
    imageAppend({
      assetId: '' as never,
      source: undefined,
      file: undefined,
    });
  }
  function handleImageChange(index: number, file?: File) {
    const value: object = getValues(`images.${index}`);
    imageUpdate(index, {
      ...value,
      file,
    } as never);
  }
  function handleImageRemove(index: number) {
    imageRemove(index);
    if (imageFields.length === 1) {
      imageAppend({
        assetId: '' as never,
        source: undefined,
        file: undefined,
      });
    }
  }
  function handleVariantRemove(index: number) {
    remove(index);
    if (fields.length === 1) {
      append({
        size: '' as never,
        price: '' as never,
        enabled: true,
      });
    }
  }

  function handleOnBlur(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const regex = /^(\d*\.?\d*)/;
    const match = e.target.value.match(regex);
    const newValue = match?.[1] ? parseFloat(match[1]) : 0;
    setValue(`variants.${index}.price`, newValue.toFixed(2) as never);
  }

  function handleThumbnailRemove() {
    resetField('thumbnail', {
      defaultValue: undefined,
    });
    setThumbnailFieldVisible(false);
    setValue('deleteExistingThumbnail', 'true' as never);
  }

  const size0 = watch(`variants.${0}.size`);
  const price0 = watch(`variants.${0}.price`);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ProductFormBasics
        collections={collections}
        suppliers={suppliers}
        disabled={disabled}
        // @ts-ignore
        register={register}
        errors={errors}
        // @ts-ignore
        setValue={setValue}
        // @ts-ignore
        defaultValues={defaultValues}
        watch={watch}
      />
      <div className="field">
        <div className="label">Miniatura</div>
        {thumbnailFieldVisible && (
          <Controller
            control={control}
            name="thumbnail"
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <input
                  type="file"
                  accept="image/*"
                  disabled={disabled}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files && event.target.files[0]);
                  }}
                  {...field}
                />
              );
            }}
          />
        )}
        {thumbnailUrl && (
          <div className="thumbnail">
            <div className="remove">
              <button type="button" onClick={handleThumbnailRemove}>
                <span className="material-icons">remove_circle</span>
              </button>
            </div>
            <img src={thumbnailUrl} alt="Miniatura" />
          </div>
        )}
      </div>
      {imageFields.map((field, index) => (
        <ProductFormImageInput
          key={field.id}
          field={field}
          index={index}
          control={control}
          register={register}
          showRemoveButton={
            imageFields.length > 1 ||
            !!imageFields[0].file ||
            !!imageFields[0].assetId
          }
          disabled={disabled}
          onRemove={handleImageRemove}
          onChange={handleImageChange}
          file={field.file}
        />
      ))}
      <div className="add">
        <button type="button" onClick={handleImageAppend}>
          Imagen <span className="material-icons">add_circle_outline</span>
        </button>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <div className="row">
              <div className="col-lg-6 padding_no">
                <div className="field" style={{ marginBottom: 0 }}>
                  <div className="label">Tamaño (g)*</div>
                  <input
                    type="text"
                    {...register(`variants.${index}.size`, {
                      setValueAs: (x) => (!!x ? Number(x) : undefined),
                    })}
                    disabled={disabled || !!watch(`variants.${index}.id`)}
                  />
                  {errors.variants && errors.variants[index]?.size && (
                    <div className="validation">
                      {errors.variants[index]?.size?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 padding_no">
                <div className="field" style={{ marginBottom: 0 }}>
                  <div className="label">Precio (€)*</div>
                  <input
                    type="text"
                    {...register(`variants.${index}.price`, {
                      setValueAs: (x) => (!!x ? Number(x) : undefined),
                      onBlur: (e) => handleOnBlur(e, index),
                    })}
                    disabled={disabled}
                  />
                  {errors.variants && errors.variants[index]?.price && (
                    <div className="validation">
                      {errors.variants[index]?.price?.message}
                    </div>
                  )}
                  {(fields.length > 1 ||
                    (size0 !== undefined && size0 !== '') ||
                    (price0 !== undefined && price0 !== '')) && (
                    <div className="remove">
                      <button
                        type="button"
                        onClick={() => handleVariantRemove(index)}
                        disabled={disabled}
                      >
                        <span className="material-icons">remove_circle</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Controller
              control={control}
              name={`variants.${index}.enabled`}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        color="success"
                        disabled={disabled}
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label=""
                    labelPlacement="end"
                  />
                );
              }}
            />
            <div className="mb-4" />
          </div>
        );
      })}
      <input type="hidden" {...register('deleteExistingThumbnail')} />
      <div className="add">
        <button type="button" onClick={handleAppend} disabled={disabled}>
          Tamaño <span className="material-icons">add_circle_outline</span>
        </button>
      </div>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <div className="submit">
          <button type="submit" disabled={disabled}>
            Guardar
          </button>
        </div>
        <CommonCancelButton disabled={disabled} />
      </Box>
    </form>
  );
}
