import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { xcollectionsQuery } from 'Category/queries';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'Common/store/hooks';
import { setCreationAlert } from 'Category/store';
import CategoryCreateForm from './Form';
import CategoryCreateSchema from 'Category/types/CreateSchema';

// mutation
const createXcategoryMutation = gql`
  mutation CreateXcategoryMutation(
    $status: XcategoryStatus!
    $name: String!
    $slug: String!
    $metaTitle: String
    $metaDescription: String
    $thumbnail: Upload
  ) {
    createXcategory(
      input: {
        status: $status
        name: $name
        slug: $slug
        metaTitle: $metaTitle
        metaDescription: $metaDescription
        thumbnail: $thumbnail
      }
    ) {
      __typename
    }
  }
`;

//
// component
//
export default function CategoryCreate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createXcategory, { loading, error }] = useMutation(
    createXcategoryMutation,
    {
      refetchQueries: [xcollectionsQuery],
    }
  );
  const [globalError, setGlobalError] = useState(false);

  async function handleSubmit(data: CategoryCreateSchema) {
    const { status, name, slug, metaTitle, metaDescription, thumbnail } = data;
    setGlobalError(false);
    try {
      await createXcategory({
        variables: {
          status,
          name,
          slug,
          metaTitle,
          metaDescription,
          thumbnail,
        },
      });
      dispatch(setCreationAlert(true));
      navigate('..');
    } catch (err) {
      console.error(err); // TODO: dev only
      setGlobalError(true);
    }
  }

  return (
    <>
      <h1>Agregar categoría de productos</h1>
      {(error || globalError) && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      <CategoryCreateForm onSubmit={handleSubmit} disabled={loading} />
    </>
  );
}
