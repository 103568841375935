import { gql } from '@apollo/client';

export const xcollectionsQuery = gql`
  query XcollectionsQuery {
    xcollections {
      items {
        id
        name
        slug
        isPrivate
      }
    }
  }
`;

export const xcollectionQuery = gql`
  query XcollectionQuery($id: ID!) {
    xcollection(id: $id) {
      isPrivate
      name
      slug
      customFields {
        metaTitle
        metaDescription
      }
      featuredAsset {
        source
      }
    }
  }
`;
