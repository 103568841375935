import { gql, useMutation, useQuery } from '@apollo/client';
import AdminForm from '../Form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { getXadminsQuery, xadminMeQuery } from 'Admin/queries';
import { useEffect, useState } from 'react';
import { setUpdatingAlert } from 'Admin/store';
import { useAppDispatch } from 'Common/store/hooks';

const xadminQuery = gql`
  query Xadmin($id: ID!) {
    xadmin(id: $id) {
      id
      firstName
      emailAddress
      customFields {
        status
        type
      }
    }
  }
`;

const updateXadminMutation = gql`
  mutation UpdateXadminMutation(
    $id: ID!
    $status: XadminStatus
    $displayName: String
    $email: String
    $password: String
  ) {
    updateXadmin(
      input: {
        id: $id
        status: $status
        displayName: $displayName
        emailAddress: $email
        password: $password
      }
    ) {
      __typename
    }
  }
`;

export default function AdminUpdate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    loading: fetchLoading,
    error: fetchError,
    data,
  } = useQuery(xadminQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });
  const [updateXadmin, { loading: updateLoading, error: updateError }] =
    useMutation(updateXadminMutation, {
      refetchQueries: [getXadminsQuery, xadminQuery],
    });
  const [globalError, setGlobalError] = useState<string | null>(null);
  const { data: xadminMeData } = useQuery(xadminMeQuery);
  const [allowDemote, setAllowDemote] = useState(false);

  useEffect(() => {
    const userId = xadminMeData?.xadminMe?.id;
    setAllowDemote(userId !== id);
  }, [xadminMeData, id]);

  async function handleSubmit(updatedData: any) {
    setGlobalError(null);
    const { displayName, email, password, status } = updatedData;
    try {
      const res = await updateXadmin({
        variables: {
          id,
          displayName,
          email,
          password,
          status,
        },
      });
      const typename = res.data?.updateXadmin?.__typename;
      switch (typename) {
        case 'Administrator': {
          dispatch(setUpdatingAlert(true));
          navigate('..');
          break;
        }
        case 'EmailAddressConflictError': {
          setGlobalError(
            'El administrador con este correo electrónico ya existe.'
          );
          break;
        }
        default: {
          setGlobalError('Ha ocurrido un error. Inténtalo de nuevo');
        }
      }
    } catch (err) {
      console.error(err);
      setGlobalError('Ha ocurrido un error. Inténtalo de nuevo');
    }
  }

  if (fetchLoading) {
    return (
      <Box textAlign="center">
        <CircularProgress sx={{ color: 'lightgray' }} />
      </Box>
    );
  }

  if (fetchError) {
    return (
      <Typography sx={{ color: 'error.main' }}>
        Ha ocurrido un error.{' '}
        <Link
          component="button"
          color="error"
          onClick={() => window.location.reload()}
        >
          Inténtalo de nuevo
        </Link>
      </Typography>
    );
  }

  const xadmin = data.xadmin;
  const xadminData = {
    ...xadmin,
    displayName: xadmin.firstName,
    status: xadmin.customFields.status,
    type: xadmin.customFields.type,
  };

  return (
    <>
      <h1>Editar administrador</h1>
      {(updateError || globalError) && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>{globalError || 'Ha ocurrido un error. Inténtalo de nuevo'}</p>
        </Box>
      )}
      <AdminForm
        data={xadminData}
        loading={updateLoading}
        statusDisabled={!allowDemote}
        typeDisabled={true}
        onSubmit={handleSubmit}
      />
    </>
  );
}
