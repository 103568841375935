import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import XadminStatus from 'Admin/types/xadmin-status';
import XadminType from 'Admin/types/xadmin-type';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CommonCancelButton from '../../../Common/components/CancelButton';

// schema
function getSchema(passwordRequired = false) {
  const lengthRegexp = passwordRequired ? /.{8,}/ : /^$|.{8,}/;
  const upperCaseRegexp = passwordRequired ? /[A-Z]+/ : /^$|[A-Z]+/;
  const lowerCaseRegexp = passwordRequired ? /[a-z]+/ : /^$|[a-z]+/;
  const numberRegexp = passwordRequired ? /[0-9]+/ : /^$|[0-9]+/;
  return z.object({
    status: z.nativeEnum(XadminStatus),
    type: z.nativeEnum(XadminType),
    displayName: z.string().nonempty('Campo requerido'),
    email: z
      .string()
      .nonempty('Campo requerido')
      .email('Correo electrónico inválido'),
    password: z
      .string()
      .min(passwordRequired ? 1 : 0, 'Campo requerido')
      .regex(lengthRegexp, 'Mínimo 8 caracteres')
      .regex(upperCaseRegexp, 'Por lo menos una letra mayúscula')
      .regex(lowerCaseRegexp, 'Por lo menos una letra minúscula')
      .regex(numberRegexp, 'Por lo menos un número'),
  });
}
const _schema = getSchema(); // just to generate a type
type Schema = z.infer<typeof _schema>;

// utility
function parseInitialData(data: any): Schema {
  const { displayName, status, type, emailAddress } = data;
  return {
    displayName,
    status,
    type,
    email: emailAddress,
    password: '',
  };
}

//
// component
//
interface Props {
  passwordRequired?: boolean;
  data?: any;
  loading?: boolean;
  statusDisabled?: boolean;
  typeDisabled?: boolean;
  onSubmit?: (data: Schema) => void;
}
export default function AdminForm({
  data,
  passwordRequired = false,
  loading = false,
  statusDisabled = false,
  typeDisabled = false,
  onSubmit = () => {},
}: Props) {
  const schema = getSchema(passwordRequired);
  const initialData = parseInitialData(data);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: initialData,
  });
  const [passwordShown, setPasswordShown] = useState(false);

  function showPassword() {
    setPasswordShown(true);
  }
  function hidePassword() {
    setPasswordShown(false);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <div className="label">Estado*</div>
          <select size={2} {...register('status')} disabled={statusDisabled}>
            <option value={XadminStatus.ACTIVE}>Activo</option>
            <option value={XadminStatus.INACTIVE}>Inactivo</option>
          </select>
          {errors.status && <div className="validation">Campo requerido</div>}
        </div>
        <div className="field">
          <div className="label">Tipo*</div>
          <select size={2} {...register('type')} disabled={typeDisabled}>
            <option value={XadminType.MANAGER}>Gestor</option>
            <option value={XadminType.SUPPLIER}>Proveedor</option>
          </select>
          {errors.type && <div className="validation">Campo requerido</div>}
        </div>
        <div className="field">
          <div className="label">Nombre*</div>
          <input {...register('displayName')} disabled={loading} />
          {errors.displayName && (
            <div className="validation">{errors.displayName.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Correo electrónico*</div>
          <input {...register('email')} disabled={loading} />
          {errors.email && (
            <div className="validation">{errors.email.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Contraseña{passwordRequired ? '*' : ''}</div>
          <input
            type={passwordShown ? 'text' : 'password'}
            {...register('password')}
            disabled={loading}
            autoComplete="new-password"
          />
          {errors.password && (
            <div className="validation">{errors.password.message}</div>
          )}
          <div className="visibility">
            {passwordShown ? (
              <button type="button" onClick={hidePassword}>
                <span className="material-icons">visibility_off</span>
              </button>
            ) : (
              <button type="button" onClick={showPassword}>
                <span className="material-icons">visibility</span>
              </button>
            )}
          </div>
        </div>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <div className="submit">
            <button type="submit" disabled={loading}>
              Guardar
            </button>
          </div>
          <CommonCancelButton disabled={loading} />
        </Box>
      </form>
    </>
  );
}
