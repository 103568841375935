import CategoryBasicsSchema from 'Category/types/BasicsSchema';
import CategoryStatus from 'Category/types/Status';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import classes from './CategoryFormBasics.module.css';
import { useEffect } from 'react';

type WatchFunction = {
  (): CategoryBasicsSchema;
  <K extends keyof CategoryBasicsSchema>(fieldName: K): CategoryBasicsSchema[K];
  <K extends keyof CategoryBasicsSchema>(fieldNames: K[]): Pick<
    CategoryBasicsSchema,
    K
  >;
};

interface Props {
  register: UseFormRegister<CategoryBasicsSchema>;
  errors: FieldErrors<CategoryBasicsSchema>;
  disabled?: boolean;
  watch: WatchFunction;
  setValue: UseFormSetValue<CategoryBasicsSchema>;
}

export default function CategoryFormBasics({
  register,
  errors,
  disabled = false,
  watch,
  setValue,
}: Props) {
  const watchMetaDescription = watch('metaDescription');

  useEffect(() => {
    const metaDescription = watchMetaDescription;
    setValue(
      'metaDescription',
      metaDescription?.replaceAll(/\n/g, '') as never
    );
  }, [setValue, watchMetaDescription]);

  return (
    <>
      <div className="field">
        <div className="label">Estado*</div>
        <select size={2} disabled={disabled} {...register('status')}>
          <option value={CategoryStatus.ACTIVE}>Activo</option>
          <option value={CategoryStatus.INACTIVE}>Inactivo</option>
        </select>
        {errors.status && <div className="validation">Campo requerido</div>}
      </div>
      <div className="field">
        <div className="label">Nombre*</div>
        <div className="count">{(watch('name') ?? '').length}</div>
        <input
          className={classes.input}
          type="text"
          disabled={disabled}
          {...register('name')}
        />
        {errors.name && <div className="validation">{errors.name.message}</div>}
      </div>
      <div className="field">
        <div className="label">Slug*</div>
        <div className="count">{(watch('slug') ?? '').length}</div>
        <input
          className={classes.input}
          type="text"
          disabled={disabled}
          {...register('slug')}
        />
        {errors.slug && <div className="validation">{errors.slug.message}</div>}
      </div>
      <div className="field">
        <div className="label">Meta Title</div>
        <div className="count">{(watch('metaTitle') ?? '').length}</div>
        <input
          className={classes.input}
          type="text"
          {...register('metaTitle')}
          disabled={disabled}
        />
      </div>
      <div className="field">
        <div className="label">Meta Description</div>
        <div className="count">{(watch('metaDescription') ?? '').length}</div>
        <textarea
          className={classes.input}
          rows={2}
          {...register('metaDescription')}
          disabled={disabled}
        />
      </div>
    </>
  );
}
