import { useQuery } from '@apollo/client';
import { rolesMeQuery } from 'Auth/queries';
import AuthRole from 'Auth/types/Role';
import { useEffect, useState } from 'react';

const managerRoleCode = 'manager';
const superAdminRoleCode = '__super_admin_role__';
const supplierRoleCode = 'supplier';

export default function useRole() {
  const { loading, error, data } = useQuery(rolesMeQuery, {
    fetchPolicy: 'no-cache',
  });
  const [role, setRole] = useState<AuthRole | null>();

  useEffect(() => {
    const roles: any[] | undefined = data?.rolesMe; // graphql schema
    if (!roles) {
      setRole(null);
    } else {
      const managerRoles = roles.filter(
        (x) => x.code === managerRoleCode || x.code === superAdminRoleCode
      );
      if (managerRoles.length > 0) {
        setRole(AuthRole.MANAGER);
      } else {
        const supplierRole = roles.find((x) => x.code === supplierRoleCode);
        setRole(!!supplierRole ? AuthRole.SUPPLIER : null);
      }
    }
  }, [data]);

  return { loading, error, data: role };
}
