import { useQuery } from '@apollo/client';
import { xcustomerQuery } from 'Customer/queries';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';
import OrderViewManager from './Manager';

interface Props {
  customerId: string;
}

export default function OrderViewCustomer({ customerId }: Props) {
  const { loading, error, data } = useQuery(xcustomerQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: customerId,
    },
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const customer = data?.xcustomer;
  const customerName = customer && `${customer.firstName} ${customer.lastName}`;

  return (
    <OrderViewManager customerId={customerId} customerName={customerName} />
  );
}
