import { gql, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { getXadminsQuery } from 'Admin/queries';
import { setCreationAlert } from 'Admin/store';
import XadminStatus from 'Admin/types/xadmin-status';
import XadminType from 'Admin/types/xadmin-type';
import { useAppDispatch } from 'Common/store/hooks';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import CommonCancelButton from '../../../Common/components/CancelButton';

// mutation
const createXadminMutation = gql`
  mutation CreateXadmin(
    $status: XadminStatus!
    $type: XadminType!
    $displayName: String!
    $email: String!
    $password: String!
  ) {
    createXadmin(
      input: {
        status: $status
        type: $type
        displayName: $displayName
        emailAddress: $email
        password: $password
      }
    ) {
      __typename
    }
  }
`;

// schema
const schema = z.object({
  status: z.nativeEnum(XadminStatus),
  type: z.nativeEnum(XadminType),
  displayName: z.string().nonempty('Campo requerido'),
  email: z
    .string()
    .nonempty('Campo requerido')
    .email('Correo electrónico inválido'),
  password: z
    .string()
    .min(1, 'Campo requerido')
    .min(8, 'Mínimo 8 caracteres')
    .regex(/[A-Z]+/, 'Por lo menos una letra mayúscula')
    .regex(/[a-z]+/, 'Por lo menos una letra minúscula')
    .regex(/[0-9]+/, 'Por lo menos un número'),
});
type Schema = z.infer<typeof schema>;

//
// component
//
export default function AdminCreate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createXadmin, { loading, error, data }] = useMutation(
    createXadminMutation,
    {
      refetchQueries: [getXadminsQuery],
    }
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [globalError, setGlobalError] = useState(false);

  function showPassword() {
    setPasswordShown(true);
  }
  function hidePassword() {
    setPasswordShown(false);
  }

  useEffect(() => {
    switch (data?.createXadmin?.__typename) {
      case 'Administrator': {
        dispatch(setCreationAlert(true));
        navigate('..');
        break;
      }
      case 'EmailAddressConflictError': {
        setError('email', {
          message: 'El administrador con este correo electrónico ya existe.',
        });
        break;
      }
      case undefined: {
        break;
      }
      default: {
        setGlobalError(true);
      }
    }
  }, [data, dispatch, navigate, setError]);

  async function _handleSubmit(_data: Schema) {
    setGlobalError(false);
    const { displayName, email, password, status, type } = _data;
    try {
      await createXadmin({
        variables: {
          displayName,
          email,
          password,
          status,
          type,
        },
      });
    } catch (err) {
      console.error(err);
      setGlobalError(true);
    }
  }

  return (
    <>
      <h1>Agregar administrador</h1>
      {(error || globalError) && (
        <Box className="success" sx={{ bgcolor: 'error.main' }}>
          <p>Ha ocurrido un error. Inténtalo de nuevo</p>
        </Box>
      )}
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <div className="field">
          <div className="label">Estado*</div>
          <select size={2} {...register('status')}>
            <option value="ACTIVE">Activo</option>
            <option value="INACTIVE">Inactivo</option>
          </select>
          {errors.status && <div className="validation">Campo requerido</div>}
        </div>
        <div className="field">
          <div className="label">Tipo*</div>
          <select size={2} {...register('type')}>
            <option value="MANAGER">Gestor</option>
            <option value="SUPPLIER">Proveedor</option>
          </select>
          {errors.type && <div className="validation">Campo requerido</div>}
        </div>
        <div className="field">
          <div className="label">Nombre*</div>
          <input {...register('displayName')} disabled={loading} />
          {errors.displayName && (
            <div className="validation">{errors.displayName.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Correo electrónico*</div>
          <input {...register('email')} disabled={loading} />
          {errors.email && (
            <div className="validation">{errors.email.message}</div>
          )}
        </div>
        <div className="field">
          <div className="label">Contraseña*</div>
          <input
            type={passwordShown ? 'text' : 'password'}
            {...register('password')}
            disabled={loading}
            autoComplete="new-password"
          />
          {errors.password && (
            <div className="validation">{errors.password.message}</div>
          )}
          <div className="visibility">
            {passwordShown ? (
              <button type="button" onClick={hidePassword}>
                <span className="material-icons">visibility_off</span>
              </button>
            ) : (
              <button type="button" onClick={showPassword}>
                <span className="material-icons">visibility</span>
              </button>
            )}
          </div>
        </div>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <div className="submit">
            <button type="submit" disabled={loading}>
              Guardar
            </button>
          </div>
          <CommonCancelButton disabled={loading} />
        </Box>
      </form>
    </>
  );
}
