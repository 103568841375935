import { useNavigate } from 'react-router-dom';

interface Props {
  disabled: boolean;
}

export default function CommonCancelButton({ disabled }: Props) {
  const navigate = useNavigate();
  return (
    <div className="cancel">
      <button
        onClick={() => {
          navigate(-1);
        }}
        type="button"
        disabled={disabled}
      >
        Cancelar
      </button>
    </div>
  );
}
