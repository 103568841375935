import { gql, useQuery } from '@apollo/client';
import OrderList from '../List';
import CommonLoading from 'Common/components/Loading';
import CommonErrorScreen from 'Common/components/ErrorScreen';

const supplierOrdersQuery = gql`
  query SupplierOrders {
    supplierOrders {
      items {
        id
        orderPlacedAt
        code
        state
      }
    }
  }
`;

export default function OrderViewSupplier() {
  const { loading, error, data } = useQuery(supplierOrdersQuery, {
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CommonLoading />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  const items = data?.supplierOrders.items ?? [];

  return (
    <>
      <h1>Órdenes</h1>
      <OrderList items={items} />
    </>
  );
}
