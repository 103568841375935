import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import CommonErrorScreen from '../ErrorScreen';
import { CircularProgress } from '@mui/material';

const logoutMutation = gql`
  mutation {
    logout {
      success
    }
  }
`;

export default function CommonMenuLogout() {
  const [logout, { data, error, loading }] = useMutation(logoutMutation);

  useEffect(() => {
    if (data?.logout?.success) {
      window.location.href = window.location.origin;
    }
  }, [data]);

  async function handleClick() {
    try {
      await logout();
    } catch (err) {
      console.error(err); // TODO: dev only
    }
  }

  if (loading) {
    return <CircularProgress size="2rem" sx={{ color: 'gray' }} />;
  }

  if (error) {
    return <CommonErrorScreen />;
  }

  return (
    <div className="logout">
      <button type="button" onClick={handleClick}>
        Cerrar sesión <span className="material-icons">logout</span>
      </button>
    </div>
  );
}
